import { configureStore } from "@reduxjs/toolkit";

//slices
import orderSlice from "./Order/orderSlice";
import walletSlice from "./Wallet/walletSlice";
import productSlice from "./Product/productSlice";
import profileSlice from "./Profile/profileSlice";
import categorySlice from "./Category/categorySlices";
import messagingSlice from "./Message/messagingSlice";

const store = configureStore({
  reducer: {
    order: orderSlice,
    wallet: walletSlice,
    profile: profileSlice,
    products: productSlice,
    category: categorySlice,
    messaging: messagingSlice,
  },
});

export default store;
