import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

//libraries
import { toast } from "react-toastify";
import Axios from "lib/axiosInterceptor";
import { ClipLoader } from "react-spinners";

//assets

//components
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import BottomNav from "./BottomNav";

//utils
import { getUserDetails } from "utils/localStorageServices";
import { successToast } from "utils/toastUtil";

export default function UserDashboardLayout() {
  const { pathname } = useLocation();

  //usestate
  const [isLoading, setIsLoading] = useState(false);

  //useffect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //variables
  let { status } = getUserDetails();
  let chlidrenClass = "lg:w-[80%] w-[99%] mx-auto ";

  //functions
  const sendVerificationMail = () => {
    setIsLoading(true);
    Axios.post(`users/resend_email_verification`)
      .then((res) => {
        successToast("Email sent successfully")
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="relative h-screen ">
      {/* navigation */}
      <Navbar />

      {/* email verification  */}
      {status === "PENDING_VERIFICATION" && (
        <section className="w-full py-1 text-center bg-red-300">
          Click{" "}
          <span
            onClick={sendVerificationMail}
            className="font-medium underline cursor-pointer"
          >
            here
          </span>{" "}
          to confirm your email
          {isLoading && <ClipLoader className="ml-4" color="black" size={12} />}
        </section>
      )}
      {/*  */}

      {!pathname?.includes("user/message/") && (
        <section className=" [ lg:hidden ] absolute bottom-0 w-full  z-50">
          <BottomNav />
        </section>
      )}

      {/* content */}
      <section className="lg:min-h-[84.9vh] bg-grey80">
        <section className="[ flex  ] gap-x-10 w-[90%] mx-auto mt-8 relative ">
          <aside className="w-[18%] [ lg:block hidden ]  sticky">
            <Sidebar />
          </aside>
          {/* main */}
          <main
            className={`${chlidrenClass} max-h-[83vh]  overflow-y-scroll  hide-scrollbar`}
          >
            <section className="self-start">
              <Outlet />
            </section>
          </main>
          {/*  */}
        </section>
      </section>
    </section>
  );
}
