import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

//assets
import google from "assets/svg/google.svg";
import facebook from "assets/svg/facebook.svg";
import show from "assets/svg/showPassword.svg";

//libraries
import axios from "axios";
import { Select } from "antd";
import { ClipLoader } from "react-spinners";

//utils
import { baseURL } from "utils/baseUrl";
import { searchOptions } from "utils/utilFunc";
import { errorToast, successToast } from "utils/toastUtil";

//slices
import { getProfileState, getProvince } from "store/Profile/profileSlice";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //useselector
  const { provinceList } = useSelector(getProfileState);

  //usestate
  const [cityId, setCityId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [provinceId, setProvinceId] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  //useeffect
  useEffect(() => {
    cityId && dispatch(getProvince(cityId));
  }, [cityId, dispatch]);

  useEffect(() => {
    const { data } = provinceList ?? {};
    if (data) {
      setProvinces((prev) =>
        data.map((province) => {
          return { value: province.name, label: province.name };
        })
      );
    }
  }, [provinceList]);

  //function

  const registerUser = (e) => {
    e.preventDefault();
    let { firstName, lastName, password, email, confirmPassword, address } =
      e.target;

    firstName = firstName.value;
    lastName = lastName.value;
    password = password.value;
    email = email.value;
    address = address.value;
    confirmPassword = confirmPassword.value;

    if (!firstName) {
      errorToast("Enter your first name");
      return;
    }

    if (!lastName) {
      errorToast("Enter your last name");
      return;
    }

    if (!email) {
      errorToast("Enter email");
      return;
    }

    if (!password) {
      errorToast("Enter password");
      return;
    }
    if (!countryCode) {
      errorToast("Select a country");
      return;
    }

    if (!cityId) {
      errorToast("Select a city");
      return;
    }
    if (!address) {
      errorToast("Enter your address");
      return;
    }

    if (!provinceId) {
      errorToast("Select province");
      return;
    }

    if (password !== confirmPassword) {
      errorToast("Passwords do not match");
      return;
    }

    const data = {
      email,
      firstName,
      lastName,
      password,
      countryCode,
      cityId: Number(cityId),
      address,
      province: provinceId,
    };
    setIsLoading(true);
    axios
      .post(`${baseURL}/users`, data)
      .then((res) => {
        successToast("Account created successfully");

        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        const errMsg = err.response.data.message ?? err.message;
        errorToast(errMsg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <section>
        <div className="mb-4">
          <h2 className="font-semibold text-center text-blue200">
            Create an account
          </h2>
        </div>

        <section className=" flex flex-col justify-center w-[90%] mx-auto  ">
          <form className="flex flex-col gap-y-4 " onSubmit={registerUser}>
            <section className="flex gap-x-2 ">
              <div className="w-1/2">
                <input
                  type="text"
                  required
                  placeholder="First Name"
                  name="firstName"
                  className="w-full px-4 py-2 border-2 rounded-lg outline-none "
                />
              </div>

              <div className="w-1/2">
                <input
                  type="text"
                  required
                  placeholder="Last Name"
                  name="lastName"
                  className="w-full px-4 py-2 border-2 rounded-lg outline-none "
                />
              </div>
            </section>

            <section>
              <div className="">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="w-full px-4 py-2 border-2 rounded-lg outline-none "
                />
              </div>
            </section>

            <section className="flex items-center gap-x-4">
              <div className="w-full h-10">
                <Select
                  showSearch
                  className="w-full h-10"
                  placeholder="Select Country"
                  filterOption={searchOptions}
                  onChange={(e) => setCountryCode(e)}
                  options={[{ value: "NG", label: "Nigeria" }]}
                />
              </div>

              <div className="w-full h-10">
                <Select
                  showSearch
                  className="w-full h-10"
                  placeholder="Select City"
                  filterOption={searchOptions}
                  onChange={(e) => setCityId(e)}
                  options={[{ value: "1", label: "Lagos" }]}
                />
              </div>
            </section>

            <div>
              <Select
                showSearch
                options={provinces}
                className="w-full h-10"
                filterOption={searchOptions}
                placeholder="Select province"
                loading={provinceList.isLoading}
                onChange={(e) => setProvinceId(e)}
              />
            </div>

            <div className="">
              <input
                type="text"
                placeholder="Address"
                name="address"
                className="w-full px-4 py-2 border-2 rounded-lg outline-none "
              />
            </div>

            <section className="flex gap-x-2">
              <div className="flex justify-between w-full px-4 py-2 border-2 rounded-lg outline-none">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="outline-none"
                />

                <img
                  src={show}
                  alt="toggle password"
                  className="cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>

              <div className="flex justify-between w-full px-4 py-2 border-2 rounded-lg outline-none">
                <input
                  type={showPassword2 ? "text" : "password"}
                  name="confirmPassword"
                  placeholder=" Confirm password"
                  className="outline-none"
                />

                <img
                  src={show}
                  alt="toggle password"
                  className="cursor-pointer"
                  onClick={() => setShowPassword2(!showPassword2)}
                />
              </div>
            </section>

            <Link to="/">
              <p className="font-semibold text-center text-blue200 ">
                Forgot password?
              </p>
            </Link>

            <button
              disabled={isLoading}
              className="w-full py-3 text-sm font-semibold rounded-full bg-yellow800 text-grey100"
            >
              {isLoading ? (
                <ClipLoader color="white" size={12} />
              ) : (
                " Create account"
              )}
            </button>
          </form>

          <section className="mt-4">
            <div className="flex p-4 text-sm font-semibold rounded-lg cursor-pointer gap-x-4 bg-grey100 text-grey200">
              <img src={google} alt="" />

              <p>Sign in with Gmail</p>
            </div>
            <div className="flex p-4 mt-2 text-sm font-semibold rounded-lg cursor-pointer gap-x-6 bg-grey100 text-grey200">
              <img src={facebook} alt="" />

              <p>Sign in with Facebook</p>
            </div>

            <p className="my-4 font-semibold text-center ">
              Already have an account?
              <Link to="/login">
                <span className="underline text-blue200 underline-offset-2">
                  {" "}
                  Login
                </span>
              </Link>
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}
