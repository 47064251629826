import React from "react";
import { ClipLoader } from "react-spinners";

export default function CustomLoader({ size = 12, color }) {
  if (color === "primary") {
    color = "#FDD200";
  } else if (color === "secondary") {
    color = "#1E3E4F";
  }

  return <ClipLoader size={size} color={color} />;
}
