import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//assets
import google from "assets/svg/google.svg";
import facebook from "assets/svg/facebook.svg";
import show from "assets/svg/showPassword.svg";

//utils
import { baseURL } from "utils/baseUrl";

//libraries
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { errorToast, successToast } from "utils/toastUtil";
import { localStorageSetter } from "utils/localStorageServices";

export default function Login() {
  const navigate = useNavigate();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);
    e.preventDefault();
    let { username, password } = e.target;

    username = username.value;
    password = password.value;

    if (!username) {
      errorToast("Enter email");
      return;
    }

    if (!password) {
      errorToast("Enter password");
      return;
    }

    const data = {
      username,
      password,
    };

    axios
      .post(`${baseURL}/auth/login`, data)
      .then((res) => {
        successToast("Welcome back");
        const token = res.data.token;
        const user = res.data.userDto;
        localStorageSetter("token", token);
        localStorageSetter("user-details", user);

        navigate("/user/shopping");
      })
      .catch((err) => {
        console.log(err);
        const errMsg = err.response.data.message ?? err.message;
        errorToast(errMsg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <section>
        <div className="mb-4">
          <h2 className="font-semibold text-center text-blue200">
            Welcome back
          </h2>
        </div>

        <section className=" flex flex-col justify-center w-[50%] mx-auto  ">
          <form className="" onSubmit={login}>
            <div>
              <input
                type="email"
                name="username"
                placeholder="Email"
                className="w-full px-4 py-2 border-2 rounded-lg outline-none "
              />
            </div>

            <div className="flex justify-between w-full px-4 py-2 mt-2 border-2 rounded-lg outline-none">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                className="outline-none"
              />

              <img
                src={show}
                alt="toggle password"
                className="cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <Link to="/reset-password">
              <p className="my-4 font-semibold text-center text-blue200">
                Forgot password?
              </p>
            </Link>

            <button className="w-full py-3 text-sm font-semibold rounded-full bg-yellow800 text-grey100">
              {isLoading ? <ClipLoader color="white" size={12} /> : " Login"}
            </button>
          </form>

          <section className="mt-4">
            <div className="flex p-4 text-sm font-semibold rounded-lg cursor-pointer gap-x-4 bg-grey100 text-grey200">
              <img src={google} alt="" />

              <p>Sign in with Gmail</p>
            </div>
            <div className="flex p-4 mt-2 text-sm font-semibold rounded-lg cursor-pointer gap-x-6 bg-grey100 text-grey200">
              <img src={facebook} alt="" />

              <p>Sign in with Facebook</p>
            </div>

            <p className="my-4 font-semibold text-center ">
              Don’t have an account?
              <Link to="/register">
                <span className="underline text-blue200 underline-offset-2">
                  {" "}
                  Register
                </span>
              </Link>
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}
