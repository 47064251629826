import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//slices
import { getProducts } from "store/Product/productSlice";
import {
  getCategories,
  getCategoriestate,
} from "store/Category/categorySlices";

//components
import Loader from "components/UI/Loader";

export default function CategoryModal({ closeModal, setActiveCate }) {
  const dispatch = useDispatch();

  //useselector
  const { categoryList } = useSelector(getCategoriestate);

  const { isLoading = true, data = [] } = categoryList ?? {};

  //useffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <section>
      <section>
        <h4 className="font-bold">Categories</h4>
      </section>

      <section>
        {isLoading && <Loader />}

        {!isLoading && (
          <section className="mt-10">
            {data?.length > 0 ? (
              <section className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-1">
                {data?.map((category) => {
                  return (
                    <p
                      key={category?.id}
                      onClick={() => {
                        setActiveCate(category);
                        closeModal();
                      }}
                      className="py-2 pl-4 text-sm font-semibold rounded cursor-pointer hover:bg-slate-200"
                    >
                      {category?.name}
                    </p>
                  );
                })}
              </section>
            ) : (
              <div className="flex items-center justify-center w-full h-[80vh] text-center">
                <p className="font-bold text-blue200">No category available </p>
              </div>
            )}
          </section>
        )}
      </section>
    </section>
  );
}
