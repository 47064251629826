import { Select } from "antd";
import React from "react";

export default function Pagination({ pagObj, setPagObj, serverPag }) {
  const { page, pageSize } = pagObj ?? {};
  const { totalItems, totalPages } = serverPag ?? {};

  const nextPage = () => {
    if (page >= totalPages) return;
    setPagObj({ ...pagObj, page: page + 1 });
  };

  const prevPage = () => {
    if (page <= 1) return;
    setPagObj({ ...pagObj, page: page - 1 });
  };
  const handlePageSizeChange = (newPageSize) => {
    setPagObj({ ...pagObj, pageSize: newPageSize, page: 1 });
  };

  const handleRefresh = () => {
    setPagObj({ ...pagObj });
  };
  return (
    <section className="flex-between my-4">
      <section className="flex-items-center gap-x-2">
        <p>
          {(page - 1) * pageSize + 1} -{" "}
          {page * pageSize > totalItems ? totalItems : page * pageSize} of{" "}
          {totalItems}
        </p>

        <i className="cursor-pointer pi pi-sync" onClick={handleRefresh}></i>
      </section>

      <section className="flex-items-center gap-x-4">
        <div className="flex-items-center gap-x-2">
          <i
            onClick={prevPage}
            className={`pi pi-arrow-left ${
              page - 1 < 1 ? "cursor-not-allowed opacity-20" : "cursor-pointer"
            } `}
          ></i>

          <p>
            {page} - {totalPages}
          </p>

          <i
            onClick={nextPage}
            className={`pi pi-arrow-right ${
              page >= totalPages
                ? "cursor-not-allowed opacity-20"
                : "cursor-pointer"
            } `}
          ></i>
        </div>

        <div className="w-[80px]">
          <Select
            onChange={handlePageSizeChange}
            defaultValue={10}
            options={[
              {
                value: 2,
                label: "2",
              },
              {
                value: 10,
                label: "10",
              },

              {
                value: 50,
                label: "50",
              },
              {
                value: 100,
                label: "100",
              },
            ]}
          />
        </div>
      </section>
    </section>
  );
}
