import React from "react";

//assets

//utils

//components
import ProductList from "components/UI/Product/ProductList";
import Filters from "components/pages/Website/Category/Filters";
import { products } from "../Homepage/data";

export default function CategoryPage() {
  return (
    <section>
      {/* breadcrumb */}
      <section className="flex">
        <p className="text-blue10">Home {">"} Games and controllers</p>
      </section>
      {/*  */}

      {/* main */}
      <section className="lg:w-[80%] mx-auto my-10">
        <section>
          <div>
            <h1 className="text-3xl font-semibold text-blue200">
              Games and controllers
            </h1>
          </div>

          <Filters />

          {/* product list */}
          <section className="pb-10 border-b">
            <ProductList products={products} />
            <ProductList products={products} />
          </section>

          {/*  */}

          {/* load more */}
          <div className="w-32 mx-auto flex justify-center items-center mt-[-25px] shadow-lg py-3 rounded-full bg-grey60 z-90 text-sm font-semibold text-blue200 px-4 cursor-pointer border">
            Load more
          </div>

          {/*  */}
        </section>
      </section>
      {/*  */}
    </section>
  );
}
