export const base64Converter = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = event.target.result;
      // Remove the preamble
      const base64WithoutPreamble = base64String.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      resolve(base64WithoutPreamble);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};


export const searchOptions = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());