//assets
import car from "assets/svg/car.svg";
import pet from "assets/svg/pets.svg";
import ball from "assets/svg/ball.svg";
import game from "assets/svg/games.svg";
import phone from "assets/svg/phone.svg";
import chair from "assets/svg/chair.svg";
import shirt from "assets/svg/shirt.svg";
import app from "assets/svg/appliances.svg";
import cabinet from "assets/svg/cabinet.svg";

export const categories = [
  {
    icon: car,
    name: "Vehicles",
  },
  {
    icon: phone,
    name: "Phones",
  },
  {
    icon: app,
    name: "Appliances",
  },
  {
    icon: chair,
    name: "Furniture",
  },
  {
    icon: ball,
    name: "Sports",
  },
  {
    icon: shirt,
    name: "Fashion",
  },
  {
    icon: pet,
    name: "Pets",
  },
  {
    icon: game,
    name: "Games",
  },
];

export const products = [
  {
    img: cabinet,
    name: "LG refrigerator | Fast cooling unit...",
    price: 200000,
    status: "available",
  },

  {
    img: cabinet,
    name: "LG refrigerator | Fast cooling unit...",
    price: 200000,
    status: "Reserved",
  },

  {
    img: cabinet,
    name: "LG refrigerator | Fast cooling unit...",
    price: 200000,
    status: "Sold",
  },
  {
    img: cabinet,
    name: "LG refrigerator | Fast cooling unit...",
    price: 200000,
    status: "Reserved",
  },
];
