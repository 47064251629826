import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getConversationDetails,
  getConversationList,
  getMessagingState,
  sendMessage,
} from "store/Message/messagingSlice";

//assets
import send from "assets/svg/Send.svg";
import avatar from "assets/svg/avatar.svg";
import Loader from "components/UI/Loader";
import { getUserDetails } from "utils/localStorageServices";
import { errorToast } from "utils/toastUtil";
import { ClipLoader } from "react-spinners";
import moment from "moment";

export default function MessageInbox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { conversationId } = useParams();

  //useref
  const inputBox = useRef(null);

  //useSelector
  const { conversationList, conversationDetails } =
    useSelector(getMessagingState);

  //usestates
  const [userId, setUserId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [firstTime, setFirstTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  //useeffects
  useEffect(() => {
    dispatch(getConversationDetails(conversationId));
    dispatch(getConversationList());
  }, [conversationId, dispatch]);

  useEffect(() => {
    const userId = getUserDetails()?.id;
    setUserId(userId);
    conversationDetails.data &&
      setMessages([...conversationDetails.data]?.reverse());
  }, [conversationDetails]);

  useEffect(() => {
    const getReceiver = conversationList.data?.find(
      (conversation) => conversation?.id === Number(conversationId)
    );

    const receiverName =
      getReceiver?.secondParticipantId === getUserDetails()?.id
        ? getReceiver?.firstParticipantName
        : getReceiver?.secondParticipantName;
    setReceiver(receiverName);
  }, [conversationList, conversationId]);

  //functions

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = moment(message.sentAt).format("MMMM Do, YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const submitMessage = () => {
    if (!message) {
      errorToast("Type in a message");
      return;
    }
    setIsLoading(true);
    if (firstTime) {
      const data = {
        message,
        productId: productDetails?.id,
      };

      //   dispatch(beginConversation(data)).then(({ type, payload }) => {
      //     setIsLoading(false);
      //     if (type?.includes("fulfilled")) {
      //       dispatch(getConversationDetails(payload?.id));
      //       dispatch(getConversationList());
      //       if (inputBox.current?.value) {
      //         inputBox.current.value = "";
      //       }
      //       setMessage(null);
      //     }
      //   });
    } else {
      const conversationId = conversationDetails.data?.[0]?.conversation_id;
      const data = {
        conversationId,
        message,
        attachment: null,
      };

      dispatch(sendMessage(data)).then(({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          if (inputBox.current?.value) {
            inputBox.current.value = "";
          }
          dispatch(getConversationDetails(conversationId));
          setMessage(null);
        }
      });
    }
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <>
      {conversationDetails.isLoading && <Loader />}

      {!conversationDetails.isLoading && (
        <section className="relative h-[85vh] ">
          {/* header  */}
          <section className="sticky inset-0 z-50 flex items-center pb-2 bg-white border-b gap-x-4">
            <div className="" onClick={() => navigate("/user/messages")}>
              <i className="font-extrabold pi pi-arrow-left"></i>
            </div>

            <section className="flex items-center gap-x-2">
              <div className="relative">
                <img src={avatar} alt="" className="w-10 h-10" />
                <div className="absolute right-0 w-2 h-2 bg-green-400 rounded-full bottom-1"></div>
              </div>

              <div className="text-sm">
                <h4 className="font-semibold text-blue20">{receiver}</h4>
                <p className="text-xs text-grey70">Active</p>
              </div>
            </section>
          </section>
          {/*  */}

          {/* messages  */}
          <section className="top-[4rem] max-h-[90%] overflow-y-scroll">
            <section className="w-[90%] mx-auto relative py-4 flex flex-col gap-y-3">
              {Object.keys(groupedMessages).map((date, index) => (
                <div key={index}>
                  <div className="mb-2 text-xs text-center bg-[#2F2F2F1A] w-fit p-2 mx-auto text-black rounded-full ">
                    {date}
                  </div>
                  {groupedMessages[date].map((message, index) => (
                    <div key={index} className="flex flex-col gap-y-3">
                      {message.participantId === userId ? (
                        <div className="flex justify-end mb-2">
                          <div className="w-auto px-6 py-3 text-sm text-right text-white rounded-t-2xl rounded-bl-2xl bg-blue200">
                            {message.messageText}
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-start mb-2">
                          <div className="px-6 py-3 text-sm text-left rounded-t-2xl rounded-br-2xl bg-grey40">
                            {message.messageText}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </section>
          </section>

          {/*  */}

          {/* type message */}

          <section className="w-[90%] mx-auto mt-[-5px] flex flex-col justify-center fixed bottom-2 ">
            <section className="flex items-center justify-between ">
              <input
                ref={inputBox}
                type="text"
                placeholder="Write a message"
                onKeyDown={(e) => e.key === "Enter" && submitMessage()}
                onChange={(e) => setMessage(e.target.value)}
                className="text-sm rounded-lg bg-grey50 outline-none  [ placeholder:text-gray90 placeholder:text-sm ] w-[90%] py-3 pl-4"
              />
              <button
                disabled={!message}
                className={`${
                  message
                    ? "opacity-100 cursor-pointer"
                    : "opacity-20 cursor-not-allowed "
                }`}
                onClick={submitMessage}
              >
                {isLoading ? (
                  <ClipLoader size={15} color="blue" />
                ) : (
                  <img src={send} alt="" className="" />
                )}
              </button>
            </section>
          </section>

          {/*  */}
        </section>
      )}
    </>
  );
}
