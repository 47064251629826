import React, { useEffect, useState } from "react";

//assets
import headset from "assets/svg/headset.svg";
import search from "assets/svg/greySearch.svg";
import inprogressImg from "assets/svg/inprogress.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMessagingState } from "store/Message/messagingSlice";
import Loader from "components/UI/Loader";
import MobileNav from "../../Layout/MobileNav";

export default function MessageMobileScreen() {
  //useselector
  const { conversationList, unreadMsgCount } = useSelector(getMessagingState);

  //usestates
  const [tab, setTab] = useState(0);
  const [conversations, setConversations] = useState([]);

  //useEffect
  useEffect(() => {
    const { data } = conversationList ?? {};

    data && setConversations((prev) => (prev = data));
  }, [conversationList]);

  const bgGradient = {
    background:
      "linear-gradient(43.09deg, #41A512 -2.16%, #41A512 42.24%, #53C31E 136.59%)",
  };
  return (
    <section className="relative h-full">
      {/* mobile screen */}
      <section className=" [ lg:hidden block ] sticky top-0 left-0 bg-white">
        <MobileNav title={"Message"} />

        {/* search */}
        <section className="w-full my-2">
          <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
            <img src={search} alt="search box" />
            <input
              type="text"
              className="w-full outline-none [ placeholder:text-xs ] text-xs"
              placeholder="Search "
            />
          </div>
        </section>
        {/*  */}

        {/* tabs */}
        <section className="[ flex items-center justify-between ] text-xs gap-x-3 my-3 [ font-medium ]">
          <div
            onClick={() => setTab((prev) => (prev = 0))}
            className={` [ px-6 py-2 ] w-1/2  text-center rounded-full cursor-pointer ${
              tab === 0 ? "text-white bg-blue200" : " bg-grey50  text-blue200"
            }`}
          >
            All
          </div>
          <div
            onClick={() => setTab((prev) => (prev = 1))}
            className={`[ flex items-center justify-center gap-x-1 ] [ px-3 py-2 ] text-center cursor-pointer rounded-full  w-1/2 ${
              tab === 1 ? "text-white bg-blue200" : " bg-grey50  text-blue200"
            } `}
          >
            {/* <img src={inprogressImg} alt="" /> */}
            <p>Unread</p>
            <span className="px-2 h-5 text-[10px] bg-white flex items-center justify-center rounded-full text-blue20">
              {unreadMsgCount.data?.total}
            </span>
          </div>

          {/* <div
            className="flex items-center px-4 py-2 text-center text-white rounded-full gap-x-2"
            style={bgGradient}
          >
            <i
              className="pi pi-check"
              style={{ color: "white", fontSize: "12px" }}
            ></i>
            <p>Completed</p>
          </div> */}
        </section>
        {/*  */}
      </section>

      <section className="w-full my-4 h-[100vh] overflow-y-scroll hide-scrollbar ">
        {conversationList.isLoading && (
          <section className="mt-[-70px]">
            <Loader />
          </section>
        )}

        {!conversationList.isLoading && (
          <section>
            {conversations?.length > 0 && (
              <section className="[ lg:hidden flex flex-col gap-y-4  ] w-full my-4 px-2">
                {conversations.map((conversation) => {
                  const { secondParticipantName, title, photo, id } =
                    conversation ?? {};

                  return (
                    <Link to={`/user/message/${id}`} key={id}>
                      <section className="w-full bg-white ">
                        <section className="flex px-2 py-3 gap-x-3">
                          <div className="w-[20%]">
                            <img
                              src={photo}
                              alt=""
                              className=" w-[3.3rem] h-[3.25rem] rounded-lg "
                            />
                          </div>

                          <section className="w-[80%]">
                            <div className="flex items-center justify-between w-full">
                              <p className="text-xs text-blue20 ">
                                {" "}
                                {secondParticipantName}
                              </p>

                              <p className="text-xs font-semibold text-grey90">
                                OCT 30
                              </p>
                            </div>
                            <p className="my-[1px] font-semibold text-grey30 text-xs">
                              {title}
                            </p>
                            <p className="text-xs text-grey70">...</p>
                          </section>
                        </section>
                      </section>
                    </Link>
                  );
                })}
              </section>
            )}
          </section>
        )}
      </section>
      {/*  */}
    </section>
  );
}
