import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//slices
import { getMessagingState } from "store/Message/messagingSlice";

//utils
import { trimText } from "utils/formatter";
import { getSavedChatProduct, saveProductForChat } from "utils/chatUtils";

//components
import Loader from "components/UI/Loader";

export default function MessageList() {
  const { conversationList, unreadMsgCount } = useSelector(getMessagingState);
  const { data } = conversationList ?? {};

  //usestate
  const [activeConversation, setActiveConversation] = useState(null);

  //useref
  const conversationRefs = useRef([]);

  //useeffect
  useEffect(() => {
    const product = getSavedChatProduct();
    if (product) {
      const doesExist = conversationList.data?.find(
        (conversation) =>
          conversation?.productId === (product?.productId ?? product?.id)
      );
      doesExist && setActiveConversation(doesExist);
    } else {
      setActiveConversation(conversationList.data?.[0]);
    }
  }, [conversationList]);

  useEffect(() => {
    if (activeConversation) {
      const activeIndex = data?.findIndex(
        (conversation) => conversation.id === activeConversation.id
      );

      if (activeIndex > 0) {
        const scrollView = conversationRefs.current[activeIndex - 1]
          ? activeIndex - 1
          : activeIndex;
        if (activeIndex !== -1 && conversationRefs.current[scrollView]) {
          conversationRefs.current[scrollView].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }
  }, [activeConversation, data]);

  const switchConversation = (message) => {
    setActiveConversation(message);
    saveProductForChat(message);
  };

  return (
    <section className="h-[82vh] relative overflow-hidden">
      <section className="relative h-full overflow-y-scroll hide-scrollbar">
        {/* overview */}
        <section className="sticky top-0 left-0 w-full bg-white border-b">
          <section className="px-6 py-4">
            <section className="flex items-center justify-between">
              <h1 className="text-lg font-semibold text-blue20">Messages</h1>
              <div className="relative pr-3 mt-2">
                <i className="pi pi-bell" style={{ fontSize: "1.4rem" }}></i>
                <p className="absolute px-2 text-xs text-white bg-red-600 rounded-full right-[-10px] bottom-[2px]">
                  {unreadMsgCount.data?.total}
                </p>
              </div>
            </section>
            <section className="flex mt-4 gap-x-4">
              <div className="[ flex items-center gap-x-2 ] border rounded-lg bg-blue200 text-white px-3 py-2 text-sm">
                <p className="text-sm font-semibold">Unread</p>
                <span className="px-2 h-5 text-[10px] bg-white flex items-center justify-center rounded-full text-blue20">
                  {unreadMsgCount.data?.total}
                </span>
              </div>
              {/* <div className="[ flex items-center gap-x-2 ] border rounded-lg bg-border text-blue20 px-3 py-2 text-sm">
                <p className="text-sm font-semibold">Sent</p>
                <span className="px-2 py-1 text-xs rounded-full bg-grey50 text-blue20">
                  {overview.sent}
                </span>
              </div> */}
            </section>
          </section>
        </section>
        {/* conversations */}
        <section className="my-4">
          <section>
            {conversationList.isLoading && <Loader />}
            {!conversationList.isLoading && (
              <section>
                {data?.length > 0 && (
                  <section className="flex flex-col cursor-pointer gap-y-2">
                    {data?.map((message, index) => {
                      const { secondParticipantName, title, photo, id } =
                        message ?? {};
                      const isActive = activeConversation?.id === id;
                      return (
                        <section
                          key={id}
                          ref={(el) => (conversationRefs.current[index] = el)}
                          onClick={() => switchConversation(message)}
                          className={`flex px-2 py-5 gap-x-3 items-center ${
                            isActive && "bg-[#143D5905]"
                          }`}
                        >
                          {isActive && (
                            <div className="w-2 h-2 rounded-full bg-primary"></div>
                          )}
                          <div>
                            <img
                              src={photo}
                              alt=""
                              className="w-[3.3rem] h-[3.25rem] rounded-lg"
                            />
                          </div>
                          <div>
                            <div className="flex items-center justify-between">
                              <p className="text-sm text-blue20">
                                {secondParticipantName}
                              </p>
                              <p className="text-xs font-semibold text-grey90">
                                OCT 30
                              </p>
                            </div>
                            <p className="my-[1px] font-semibold text-grey30 text-sm">
                              {title}
                            </p>
                            <p className="text-sm text-grey70">
                              {trimText(
                                "What’s the last price for the headphone?",
                                40
                              )}
                            </p>
                          </div>
                        </section>
                      );
                    })}
                  </section>
                )}
              </section>
            )}
          </section>
        </section>
        {/* */}
      </section>
    </section>
  );
}
