import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//services
import {
  _createProduct,
  _editProduct,
  _getMerchantProducts,
  _getProductDetails,
  _getProducts,
} from "services/product-services";
const initialState = {
  merchantProductList: {
    isLoading: false,
    data: null,
  },

  productList: {
    isLoading: false,
    data: null,
  },

  productDetails: {
    isLoading: false,
    data: null,
  },
};

//GET

export const getMerchantProducts = createAsyncThunk(
  "products/getMerchantProducts",
  async (params) => {
    const response = await _getMerchantProducts(params);
    return response.data;
  }
);

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (params) => {
    const response = await _getProducts(params);
    return response.data;
  }
);

export const getProductDetails = createAsyncThunk(
  "products/getProductDetails",
  async (id) => {
    const response = await _getProductDetails(id);
    return response.data;
  }
);

//POST
export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (data) => {
    const response = await _createProduct(data);
    return response.data;
  }
);

//PATCH
export const editProduct = createAsyncThunk(
  "products/editProduct",
  async (data) => {
    const response = await _editProduct(data);
    return response.data;
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    //GET

    //get merchant product
    builder
      .addCase(getMerchantProducts.pending, (state) => {
        state.merchantProductList.isLoading = true;
      })
      .addCase(getMerchantProducts.fulfilled, (state, { payload }) => {
        state.merchantProductList.isLoading = false;
        state.merchantProductList.data = payload;
      })
      .addCase(getMerchantProducts.rejected, (state, { payload }) => {
        state.merchantProductList.isLoading = false;
      });

    //product list
    builder
      .addCase(getProducts.pending, (state) => {
        state.productList.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.productList.isLoading = false;
        state.productList.data = payload;
      })
      .addCase(getProducts.rejected, (state, { payload }) => {
        state.productList.isLoading = false;
      });

    //product details
    builder
      .addCase(getProductDetails.pending, (state) => {
        state.productDetails.isLoading = true;
      })
      .addCase(getProductDetails.fulfilled, (state, { payload }) => {
        state.productDetails.isLoading = false;
        state.productDetails.data = payload;
      })
      .addCase(getProductDetails.rejected, (state, { payload }) => {
        state.productDetails.isLoading = false;
      });
  },
});

export const getProductState = (state) => state.products;
export default productSlice.reducer;
