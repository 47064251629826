//assets
import dateImg from "assets/svg/date.svg";
import productImg from "assets/svg/products.svg";
import checkImg from "assets/svg/circleCheck.svg";
import categoryImg from "assets/svg/category.svg";
import { formatAmount, toLowerCase } from "utils/formatter";
import { Link } from "react-router-dom";

export const products = [
  {
    id: "1000",
    code: "f230fh0g3",
    name: "Bamboo Watch",
    description: "Product Description",
    image: "bamboo-watch.jpg",
    price: 65,
    category: "Accessories",
    quantity: 24,
    inventoryStatus: "INSTOCK",
    rating: 5,
  },

  {
    id: "1000",
    code: "f230fh0g3",
    name: "Bamboo Watch",
    description: "Product Description",
    image: "bamboo-watch.jpg",
    price: 65,
    category: "Accessories",
    quantity: 24,
    inventoryStatus: "INSTOCK",
    rating: 5,
  },
];

export const indexTemplate = (rowData, rowIndex) => rowIndex.rowIndex + 1;

export const columns = [
  {
    width: "10px",
    body: (rowData, rowIndex) => indexTemplate(rowData, rowIndex),
    header: "#",
  },

  {
    field: "name",
    header: "Products",
    icon: productImg,
    body: (rowData) => (
      <Link
        className="hover:font-semibold"
        to={`/user/edit-product/${rowData?.id}`}
      >
        {rowData.name}{" "}
      </Link>
    ),
  },
  { field: "brand", header: "Brand", icon: categoryImg },
  {
    field: "price",
    header: "amount",
    icon: dateImg,
    body: (rowData) => {
      return formatAmount(rowData.amount);
    },
  },
  {
    field: "orderStatus",
    header: "status",
    icon: dateImg,
    body: (rowData) => {
      const { orderStatus } = rowData;
      let status = orderStatus?.split("_").join(" ");
      return (
        <div className="text-sm font-semibold text-center w-fit">
          {toLowerCase(status) === "available" ? (
            <p className="px-3 py-2 text-green60 capitalize bg-green50 rounded-full">
              Completed
            </p>
          ) : (
            <p className="px-3 py-1  text-yellow200 capitalize bg-yellow50 rounded-full ">
              Review
            </p>
          )}
        </div>
      );
    },
  },

  { field: "description", header: "Description", icon: checkImg },
];
