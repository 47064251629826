import React, { useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

//utils
import { baseURL } from "utils/baseUrl";

//assets
import show from "assets/svg/showPassword.svg";

//libraries
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  //useref
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [password1, setPassword1] = useState(false);
  const [password2, setPassword2] = useState(false);

  //function
  const changePassword = (e) => {
    e.preventDefault();

    let newPassword = passwordRef.current.value;
    let confirmPassword = confirmPasswordRef.current.value;

    if (!newPassword || !confirmPassword) {
      toast.error("fill all fields");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Both passwords must be the same");
      return;
    }
    const data = {
      newPassword,
      token,
    };
    setIsLoading(true);
    console.log(data);

    axios
      .post(`${baseURL}/users/reset_password`, data)
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <section>
        <div className="mb-4">
          <h2 className="text-lg font-semibold text-center text-blue200">
            Create a new password
          </h2>
        </div>

        <section className=" flex flex-col justify-center w-[50%] mx-auto  ">
          <form className="text-sm" onSubmit={changePassword}>
            <div>
              <label htmlFor="enterPassword" className="font-medium">
                {" "}
                Enter new password
              </label>
              <div className="flex justify-between w-full px-4 py-2 mt-2 border-2 rounded-lg outline-none">
                <input
                  ref={passwordRef}
                  placeholder="Password"
                  className="outline-none"
                  type={!password1 ? "password" : "text"}
                />

                <img
                  src={show}
                  alt="toggle password"
                  className="cursor-pointer"
                  onClick={() => setPassword1(!password1)}
                />
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="confirmPassword" className="font-medium">
                {" "}
                Confirm password
              </label>
              <div className="flex justify-between w-full px-4 py-2 mt-2 border-2 rounded-lg outline-none">
                <input
                  ref={confirmPasswordRef}
                  className="outline-none"
                  placeholder="Confirm password"
                  type={!password2 ? "password" : "text"}
                />

                <img
                  src={show}
                  alt="toggle password"
                  className="cursor-pointer"
                  onClick={() => setPassword2(!password2)}
                />
              </div>
            </div>

            <button
              disabled={isLoading}
              className="w-full py-3 mt-6 text-sm font-semibold rounded-full bg-yellow800 text-grey100"
            >
              {isLoading ? <ClipLoader color="white" size={12} /> : " Submit"}
            </button>
          </form>

          <section className="mt-4">
            <p className="my-4 font-semibold text-center ">
              Don't have an account?
              <Link to="/register">
                <span className="underline text-blue200 underline-offset-2">
                  {" "}
                  Register
                </span>
              </Link>
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}
