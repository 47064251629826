import Axios from "lib/axiosInterceptor";

//get
export const _getOrderHistory = (params) => {
  return Axios.get(`orders/history`, {
    params: {
      ...params,
    },
  });
};

//post
export const _beginOrder = (data) => {
  return Axios.post(`/orders`, data);
};

export const _orderPaymentStatus = (orderId) => {
  return Axios.post(`orders/payment/status/${orderId}`);
};
