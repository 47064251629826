import React from "react";
import { Link } from "react-router-dom";

//assets
import search from "assets/svg/greySearch.svg";

export default function LargeScreenNav() {
  return (
    <section className=" [ lg:flex hidden items-center justify-between ] ">
      <h1 className="text-lg font-semibold text-blue200">Products</h1>

      <section className="[ flex items-center justify-between gap-x-10 ] ">
        <section className="[ flex items-center justify-between gap-x-4 ] ">
          <div className=" [ flex items-center gap-x-2 ] border rounded-full text-blue200  px-4 py-2">
            <p className="text-sm font-semibold ">In progress</p>
            <span className="px-3 py-1 text-xs rounded-full bg-grey50">20</span>
          </div>

          <div className=" [ flex items-center gap-x-2 ] border rounded-full bg-blue200 text-white  px-4 py-2">
            <p className="text-sm font-semibold">Completed</p>
            <span className="px-3 py-1 text-xs rounded-full bg-[#FFFFFF26]">
              20
            </span>
          </div>

          <Link to="/user/add-product">
            <div className=" [ flex items-center gap-x-2 ] border rounded-full text-blue200 bg-primary  px-4 py-2">
              {/* <img src={activeProduct} alt="add product" /> */}
              <p className="text-sm font-semibold"> + Add Product</p>
            </div>
          </Link>
        </section>

        <section className="w-[18rem]">
          <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
            <img src={search} alt="search box" />
            <input
              type="text"
              className="w-full outline-none [ placeholder:text-sm ] text-sm"
              placeholder="Search"
            />
          </div>
        </section>
      </section>
    </section>
  );
}
