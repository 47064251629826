import Loader from "components/UI/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


//slices
import { getProducts } from "store/Product/productSlice";import {
  getCategories,
  getCategoriestate,
} from "store/Category/categorySlices";

export default function CategorySidebar({ openCate, setOpenCate }) {
  const dispatch = useDispatch();

  //useselector
  const { categoryList } = useSelector(getCategoriestate);

  const { isLoading = true, data = [] } = categoryList ?? {};

  //usestate
  const [activeCate, setActiveCate] = useState(null);

  //useffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      category_id: activeCate,
    };
    dispatch(getProducts(params));
  }, [dispatch, activeCate]);

  return (
    <section
      className={`fixed top-0  z-50  lg:w-[20%] md:w-6/12 w-10/12 h-full  overflow-y-scroll bg-white shadow py-4 px-4 transition-all duration-700 ease-in-out ${
        openCate ? "left-0" : "left-[-1000px]"
      }`}
    >
      <section className="flex items-center justify-between">
        <h2>Categories</h2>

        <i
          className="cursor-pointer pi pi-times"
          onClick={() => setOpenCate(false)}
        ></i>
      </section>

      {isLoading && <Loader />}

      {!isLoading && (
        <section className="mt-10">
          {data?.length > 0 ? (
            <section className="flex flex-col gap-y-2">
              <p
                onClick={() => setActiveCate("")}
                className="py-2 pl-4 text-sm rounded cursor-pointer hover:bg-slate-200"
              >
                All
              </p>
              {data?.map((category) => {
                return (
                  <p
                    key={category?.id}
                    onClick={() => setActiveCate(category?.id)}
                    className="py-2 pl-4 text-sm rounded cursor-pointer hover:bg-slate-200"
                  >
                    {category?.name}
                  </p>
                );
              })}
            </section>
          ) : (
            <div className="flex items-center justify-center w-full h-[80vh] text-center">
              <p className="font-bold text-blue200">No category available </p>
            </div>
          )}
        </section>
      )}
    </section>
  );
}
