import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//assets
import msg from "assets/svg/msg.svg";
import plus from "assets/svg/plus.svg";
import saved from "assets/svg/saved.svg";
import avatar from "assets/svg/avatar.svg";
import profile from "assets/svg/profile.svg";
import fullLogo from "assets/svg/fullLogo.svg";
import arrowDown from "assets/svg/arrowDown.svg";

//components
import FlexContainer from "../../../UI/StyleComponents/FlexContainer";

//utils
import { getToken, getUserDetails } from "utils/localStorageServices";

//slices
import {
  getMessagingState,
  getUnreadMsgCount,
} from "store/Message/messagingSlice";

//libraries
import { ClipLoader } from "react-spinners";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { unreadMsgCount } = useSelector(getMessagingState);

  //usestate
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  //useseffect
  useEffect(() => {
    const userDetails = getUserDetails();
    setIsLoggedIn(getToken());
    setUserDetails(userDetails);

    getToken() && dispatch(getUnreadMsgCount());
  }, [dispatch]);

  //functions
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="text-sm border-b ">
      <section className="w-[80%] mx-auto py-4">
        <FlexContainer>
          <section>
            <Link to="/">
              <img src={fullLogo} alt="flexbuy logo" className="w-24 lg:w-36" />
            </Link>
          </section>

          <section>
            <div className="flex items-center lg:hidden gap-x-4">
              <img src={profile} alt="profile" className="cursor-pointer" />
              <img src={msg} alt="inbox" className="cursor-pointer" />
            </div>
            <FlexContainer extraClass="gap-x-4 hidden lg:flex">
              {/* <div>
                <FlexContainer extraClass="gap-x-2">
                  <img src={saved} alt="saved items" />
                  <p> Saved items</p>
                </FlexContainer>
              </div> */}

              {isLoggedIn && (
                <div>
                  <Link to="/user/messages">
                    <FlexContainer extraClass="gap-x-2 relative text-sm">
                      <div className="relative">
                        {unreadMsgCount.data?.total && (
                          <div className="w-fit absolute right-[-10px] top-[-10px] rounded-full bg-secondary text-xs text-white h-4 justify-center items-center px-2">
                            {unreadMsgCount.data?.total}
                          </div>
                        )}
                        <img src={msg} alt="inbox" />
                      </div>
                    </FlexContainer>
                  </Link>
                </div>
              )}

              {isLoggedIn && (
                <FlexContainer
                  isresponsive={true}
                  extraClass="px-2 cursor-pointer py-1  gap-x-4 border rounded-full items-center"
                >
                  <FlexContainer extraClass="gap-x-2">
                    <img src={avatar} alt="avatar" />
                    <p>{userDetails?.firstName}</p>
                  </FlexContainer>

                  <div className="mt-1">
                    <img src={arrowDown} alt="arrow" />
                  </div>
                </FlexContainer>
              )}

              <Link to="/user/products">
                <button className="flex items-center px-6 py-2 text-sm font-semibold rounded-full gap-x-2 text-blue200 bg-primary">
                  {!isLoggedIn ? (
                    <>
                      <img src={plus} alt="sell a product" className="w-3" />
                      <span>Sell a product</span>
                    </>
                  ) : (
                    <p>Dashboard</p>
                  )}
                </button>
              </Link>

              {isLoggedIn ? (
                <button onClick={logout} className="flex items-center gap-x-1">
                  <i className="pi pi-sign-out"></i>
                  Logout
                </button>
              ) : (
                <Link to={"/login"}>
                  <button className="flex items-center text-lg gap-x-1">
                    Login
                  </button>
                </Link>
              )}
            </FlexContainer>
          </section>
        </FlexContainer>
      </section>
    </nav>
  );
}
