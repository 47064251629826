import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//slices
import {
  getCategories,
  getCategoriestate,
} from "store/Category/categorySlices";

//assets
import upload from "assets/svg/upload.svg";
import imgPlaceholder from "assets/svg/imgPlaceholder.svg";
import { toast } from "react-toastify";
import { base64Converter } from "utils/utilFunc";
import { createProduct } from "store/Product/productSlice";
import { ClipLoader } from "react-spinners";

export default function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

  //useref
  const fileUpload = useRef(null);

  //useselector
  const { categoryList } = useSelector(getCategoriestate);

  //usestate
  const [chips, setChips] = useState([]);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [imageInComplete, setImageIncomplete] = useState(true);
  const [productImgs, setProductImgs] = useState(Array(8).fill(null));

  //useeffect
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (categoryList.data) {
      const morphedList = categoryList.data.map((cate) => {
        return {
          value: cate.id,
          label: cate.name,
        };
      });

      setCategories(morphedList);
    }
  }, [categoryList]);

  useEffect(() => {
    setImageIncomplete(productImgs.some((img) => !img));
  }, [productImgs]);

  //function
  const handleAddChip = (event) => {
    if (
      (event.key === "Enter" || event.key === ",") &&
      inputValue.trim() !== ""
    ) {
      event.preventDefault();
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }

    // if (event.key === "Backspace") {
    //   const newChips = [...chips];
    //   newChips.pop();
    //   setChips(newChips);
    // }
    // console.log(event.key);
  };

  const handleRemoveChip = (index) => {
    const newChips = [...chips];
    newChips.splice(index, 1);
    setChips(newChips);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const triggerImgUpload = () => {
    imageInComplete && fileUpload.current.click();
  };

  const uploadProductImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      if (!image.type.startsWith("image")) {
        toast.error("Upload a valid image file");
        return;
      }

      if (image.size > MAX_FILE_SIZE_BYTES) {
        toast.error("Image file is too large");
        return;
      }

      for (let i = 0; i < productImgs.length; i++) {
        const element = productImgs[i];

        if (!element) {
          const images = [...productImgs];
          images[i] = image;
          setProductImgs(images);

          return;
        }
      }
    }
  };

  const removeImage = (index) => {
    const images = [...productImgs];
    images[index] = null;
    setProductImgs(images);
  };

  const addProduct = async (e) => {
    e.preventDefault();
    let { name, brand, description, price } = e.target;
 

    name = name.value;
    brand = brand.value;
    price = Number(price.value);
    description = description.value;

    if (!name || !brand || !price || !description || !category) {
      toast.error("Kindly fill all information");
      return;
    }

    if (productImgs.length < 1) {
      toast.error("Upload an image");
      return;
    }

    setIsLoading(true);
    try {
      const base64Images = await Promise.all(
        productImgs
          .filter((img) => img !== null) // Filter out null values
          .map((img) => base64Converter(img))
      );

      console.log(base64Images);

      const data = {
        name,
        price,
        description,
        brand,
        categoryId: category,
        tags: chips,
        photos: base64Images,
      };

      console.log(data);

      dispatch(createProduct(data)).then(({payload}) => {
        setIsLoading(false);
        if(payload){
          toast.success("Product added successfully")
          navigate('/user/products')
        }
      });
    } catch (err) {}
  };

  //style
  const bgGradient = {
    background:
      "linear-gradient(129.15deg, #143D5910 -0.29%, #FBC20010 108.54%)",
  };
  return (
    <section className="mx-auto lg:w-7/12">
      <Link to="/user/products">
        <div className=" flex [ lg:justify-start justify-center ] [ lg:mb-0 mb-2 ]">
          <div className="flex items-center w-40 px-4 py-2 text-sm text-center rounded-full cursor-pointer bg-grey50 ">
            <i className="pi pi-angle-left" style={{ color: "slateblue" }}></i>{" "}
            Back to products
          </div>
        </div>
      </Link>

      <h2 className="font-semibold text-center text-blue200">
        Ready to sell stuff you don’t need ?
      </h2>

      <section className="px-6 py-10 my-4 text-sm bg-white border rounded-lg">
        <h3 className="text-lg font-semibold">Product details</h3>

        <form onSubmit={addProduct} className="flex flex-col my-4 gap-y-4">
          <div className="flex flex-col gap-y-2">
            <label> What do you want to sell?</label>
            <input
              name="name"
              type="text"
              placeholder="Name of the product"
              className=" [ py-3 px-4 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90  ]"
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label> What brand?</label>
            <input
              name="brand"
              type="text"
              placeholder="e.g LG, Hisense, Samsung"
              className=" [ py-3 px-4 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label> Choose category</label>
            <Select
              className="h-10"
              options={categories}
              loading={categoryList.isLoading}
              onChange={(value) => setCategory(value)}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label> Tell us about it</label>
            <textarea
              className=" [ py-3 px-4 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
              name="description"
              id=""
              rows="3"
              placeholder="Enter a short description"
            ></textarea>
          </div>

          {/* <div className="flex flex-col gap-y-2">
            <label>What's the product condition?</label>
            <input
              type="text"
              placeholder="Brand new"
              className=" [ py-3 px-4 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
            />
          </div> */}

          <div className="flex flex-col gap-y-2">
            <label>Product tags</label>
            <div
              type="text"
              className=" flex items-center  max-w-xl flex-wrap [ py-1 px-2 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
            >
              {chips.map((chip, index) => (
                <div
                  key={index}
                  className="flex items-center px-2 py-1 m-1 bg-gray-200 rounded-full"
                >
                  {chip}
                  <button
                    className="ml-2 text-gray-600 border-none cursor-pointer bg-none hover:text-gray-800"
                    onClick={() => handleRemoveChip(index)}
                  >
                    x
                  </button>
                </div>
              ))}

              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleAddChip}
                placeholder="Enter tag"
                className="flex-1 p-1 m-1 border-none outline-none"
              />
            </div>
          </div>

          <div className="flex flex-col pb-2 border-b gap-y-2">
            <label>Price</label>
            <input
              name="price"
              type="text"
              placeholder="e.g 20,000.00"
              className=" [ py-3 px-4 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
            />

            <p className="py-2 text-center text-blue200">
              For every purchase, Flexbuy charges 2% of product price
            </p>
          </div>

          <section className="px-2">
            <h3 className="mb-2 text-lg font-semibold">Photos</h3>

            <section>
              <section
                style={bgGradient}
                disabled={imageInComplete}
                onClick={triggerImgUpload}
                className={`rounded-xl w-full py-4 text-sm text-blue200 text-center font-semibold  ${
                  !imageInComplete ? "cursor-not-allowed" : "cursor-pointer"
                } `}
              >
                <div className="flex justify-center">
                  <img src={upload} alt="" />
                </div>

                <p className="my-4">Drag or drop image</p>

                <p
                  className={`text-white rounded-full bg-blue200 px-6 py-2 w-40 mx-auto ${
                    !imageInComplete ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                >
                  Choose image
                </p>
              </section>

              <input
                ref={fileUpload}
                type="file"
                className="hidden"
                onChange={(e) => uploadProductImage(e)}
              />
            </section>

            <section className="grid items-center justify-center grid-cols-2 my-4 lg:grid-cols-4 md:grid-cols-3 gap-x-4 gap-y-4">
              {productImgs.map((productImg, index) => {
                return (
                  <div
                    key={index}
                    className="lg:w-[6.4138rem] lg:h-[5.7325rem] w-[6rem] h-[5rem] bg-grey150 border border-grey40 rounded-lg border-dashed"
                  >
                    <div className="relative flex flex-col items-center justify-center w-full h-full ">
                      {productImg && (
                        <>
                          <div
                            onClick={() => removeImage(index)}
                            className="absolute top-[-5px] right-[-10px] bg-slate-200 rounded-full px-2 py-1 text-xs font-semibold cursor-pointer"
                          >
                            X
                          </div>
                          <img
                            src={URL.createObjectURL(productImg)}
                            alt=""
                            className="rounded-lg lg:w-[6.4138rem] lg:h-[5.7325rem] w-[6rem] h-[5rem]"
                          />
                          {index === 0 && (
                            <div className=" w-full rounded-b-md bg-primary text-blue200 text-center font-semibold text-xs absolute bottom-[-10px]">
                              Main photo
                            </div>
                          )}
                        </>
                      )}

                      {!productImg && <img src={imgPlaceholder} alt="" />}
                    </div>
                  </div>
                );
              })}
            </section>
          </section>

          <button
            className="w-full py-3 mt-2 text-white rounded-full bg-blue200"
            type="submit"
          >
            {isLoading ? <ClipLoader size={12} color="white" /> : "Submit"}
          </button>
        </form>
      </section>
    </section>
  );
}
