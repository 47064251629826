import React from "react";
import { mobileLinks } from "./data";

export default function BottomNav() {
  return (
    <section className="w-full bg-white ">
      <div className="[ w-[85%] mx-auto ] [ flex justify-between items-center ]  h-[4.5rem]">
        {mobileLinks?.map((nav, index) => {
          return (
            <div
              key={index}
              className="flex flex-col justify-center item-center"
            >
              {nav.name === "Sell" ? (
                <img
                  src={nav.active}
                  alt={nav.name}
                  className="w-10 mx-auto mt-[-30px]"
                />
              ) : (
                <img src={nav.active} alt={nav.name} className="w-5 mx-auto" />
              )}

              <p className="mt-2 text-xs text-center"> {nav.name}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
