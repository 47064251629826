//assets
import avatar from "assets/svg/avatar.svg";
import sellIcon from "assets/svg/sellIcon.svg";
import activeFav from "assets/svg/activeFav.svg";
import activeMsg from "assets/svg/activeMsg.svg";
import activeHome from "assets/svg/activeHome.svg";
import inactiveMsg from "assets/svg/inactiveMsg.svg";
import activeSales from "assets/svg/activeSales.svg";
import activeWallet from "assets/svg/activeWallet.svg";
import inactiveLove from "assets/svg/inactiveLove.svg";
import activeProduct from "assets/svg/activeProduct.svg";
import mobileFav from "assets/svg/mobileInactiveFav.svg";
import mobileMsg from "assets/svg/mobileInactiveMsg.svg";
import inactiveSales from "assets/svg/inactiveSales.png";
import activeSettings from "assets/svg/activeSettings.svg";
import inactiveWallet from "assets/svg/inactiveWallet.svg";
import activeShopping from "assets/svg/activeShopping.svg";
import inactiveProduct from "assets/svg/inactiveProduct.svg";
import inactiveSettings from "assets/svg/inactiveSettings.svg";
import inactiveShopping from "assets/svg/inactiveShopping.svg";


export const mobileLinks = [
  {
    name: "Home",
    link: "shopping",
    active: activeHome,
    inactive: activeHome,
  },
  {
    name: "Favorites",
    link: "favorites",
    active: mobileFav ,
    inactive: mobileFav ,
  },

  {
    name: "Sell",
    link: "Sell",
    active: sellIcon,
    inactive: sellIcon,
  },

  {
    name: "Messages",
    link: "messages",
    active: mobileMsg,
    inactive: mobileMsg,
  },
  {
    name: "You",
    link: "messages",
    active: avatar,
    inactive: avatar,
  },
]



export const navLinks = [
  {
    name: "Shopping",
    link: "shopping",
    active: activeShopping,
    inactive: inactiveShopping,
  },

  {
    name: "Sales",
    link: "sales",
    active: activeSales,
    inactive: inactiveSales,
  },

  {
    name: "Products",
    link: "products",
    active: activeProduct,
    inactive: inactiveProduct,
  },

  {
    name: "Messages",
    link: "messages",
    active: activeMsg,
    inactive: inactiveMsg,
  },

  {
    name: "Favorites",
    link: "favorites",
    active: activeFav,
    inactive: inactiveLove,
  },

  {
    name: "Wallet",
    link: "wallet",
    active: activeWallet,
    inactive: inactiveWallet,
  },

  {
    name: "Settings",
    link: "settings",
    active: activeSettings,
    inactive: inactiveSettings,
  },
];
