import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//assets
import breadcrumb from "assets/svg/breadcrumb.svg";

//components
import CategorySidebar from "./CategorySidebar";
import Footer from "components/pages/Website/Layout/Footer";
import Navbar from "components/pages/Website/Layout/Navbar";

export default function WebsiteLayout({ children }) {
  const { pathname } = useLocation();

  //usestate
  const [openCate, setOpenCate] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // useeffect

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0,0);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let chlidrenClass =
    pathname === "/" && screenWidth < 400
      ? "lg:w-[80%] w-[100%] mx-auto my-10"
      : "lg:w-[80%] w-[90%] mx-auto my-10";

  return (
    <section className="">
      <CategorySidebar openCate={openCate} setOpenCate={setOpenCate} />

      <section
        className="max-w-[1400px] mx-auto "
        onClick={() => setOpenCate(false)}
      >
        {/* navigation */}
        <Navbar />

        {/*  */}

        {/* subnav */}
        <section className="py-4 border-b">
          <section className="flex w-[80%] mx-auto  gap-x-80 ">
            <section
              className="flex cursor-pointer gap-x-2"
              onClick={(e) => {
                e.stopPropagation();
                setOpenCate(true);
              }}
            >
              <img src={breadcrumb} alt="open categories" />
              <p className="font-semibold"> All categories</p>
            </section>

            {pathname === "/" ||
              (pathname?.includes("/product/") && (
                <section className=" [ lg:flex hidden ] font-semibold gap-x-2 text-sm">
                  <p>Cars</p>
                  <p>Gadgets</p>
                  <p>Games & Console</p>
                  <p>Computers and Electronics</p>
                </section>
              ))}
          </section>
        </section>

        {/*  */}

        {/* main */}
        <main className={`${chlidrenClass}`}>
          <section className="my-10">{children}</section>
        </main>
        {/*  */}

        <Footer />
      </section>
    </section>
  );
}
