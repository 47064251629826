import React, { useState } from "react";

//assets
import left from "assets/svg/leftImg.svg";
import cabinet from "assets/svg/cabinet.svg";
import right from "assets/svg/imageRight.svg";

export default function ImageGallery({ images }) {
  //usestate
  const [activeImg, setActiveImg] = useState(images?.[0]?.url);

  return (
    <section className="flex items-center  [ lg:gap-x-2 gap-y-2 ] [ lg:flex-row flex-col ]">
      {/* main image */}
      <section>
        <div className="relative">
          {images?.length > 1 && (
            <img
              src={right}
              alt=""
              className="absolute top-[45%] left-4 cursor-pointer"
            />
          )}

          <img
            src={activeImg}
            alt=""
            className=" object-contain lg:w-[38rem] lg:h-[388.82px] md:w-[28rem] md:h-[28rem]  h-[20rem] rounded-2xl"
          />

          {images?.length > 1 && (
            <img
              src={left}
              alt=""
              className="absolute top-[45%] right-4 cursor-pointer"
            />
          )}
        </div>
      </section>

      {/*  */}

      {/* other images */}

      {images?.length > 1 && (
        <section className="w-full lg:w-auto">
          <div className="flex gap-y-3 [ flex-row lg:flex-col ] [ justify-between items-center  ]">
            <img
              src={cabinet}
              alt=""
              className=" [ lg:w-[4.8rem] w-[3.6rem] ] rounded-lg  object-contain "
            />

            <img
              src={cabinet}
              alt=""
              className=" [ lg:w-[4.8rem] w-[3.6rem] ] rounded-lg   object-contain "
            />
            <img
              src={cabinet}
              alt=""
              className=" [ lg:w-[4.8rem] w-[3.6rem] ] rounded-lg   object-contain "
            />
            <img
              src={cabinet}
              alt=""
              className=" [ lg:w-[4.8rem] w-[3.6rem] ] [ lg:hidden block ] rounded-lg   object-contain "
            />

            <div className=" [ lg:w-[4.8rem] w-[3.6rem] ] rounded-lg  [ lg:py-6 py-5 ]  flex items-center justify-center bg-blue200 text-white font-semibold text-lg">
              <p> +8</p>
            </div>
          </div>
        </section>
      )}

      {/*  */}
    </section>
  );
}
