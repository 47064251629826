export const saveProductForChat = (product) => {
  localStorage.setItem("productChat", JSON.stringify(product));

  window.dispatchEvent(
    new CustomEvent("switchedConversation", { detail: product })
  );
};

export const getSavedChatProduct = () => {
  const product = localStorage.getItem("productChat");
  return product ? JSON.parse(product) : null;
};

export const removeChatProduct = () => {
  localStorage.removeItem("productChat");
};
