import React from "react";

//assets
import authSide from "assets/svg/authSide.svg";

export default function AuthLayout({ children }) {
  return (
    <section className="relative w-full h-screen">
      <aside className="absolute left-0 w-[14.5%] ">
        <img src={authSide} alt="authentication" className="h-screen " />
      </aside>

      <main className="absolute right-0 w-[85.5%]  h-screen">
        <section className="w-[50%] mx-auto flex items-center h-full ">
            <section className="w-full">
            {children}
            </section>
       
        </section>
      </main>
    </section>
  );
}
