import React from 'react'
import MessageList from './MessageList'
import MessageBox from './MessageBox'

export default function MessageLargeScreen() {
  return (
    <section className="flex gap-x-4">
      <section className="w-4/12 border rounded-lg">
        <MessageList />
      </section>

      <section className="w-7/12">
        <MessageBox />
      </section>
    </section>
  )
}
