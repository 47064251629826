import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _beginConversation,
  _getConversationDetails,
  _getConversationList,
  _getUnreadMsgCount,
  _sendMessage,
} from "services/messaging-services";

const initialState = {
  unreadMsgCount: {
    isLoading: false,
    data: null,
  },
  conversationList: {
    isLoading: false,
    data: null,
  },

  productList: {
    isLoading: false,
    data: null,
  },

  conversationDetails: {
    isLoading: false,
    data: null,
  },
};

//GET

export const getUnreadMsgCount = createAsyncThunk(
  "messaging/getUnreadMsgCount",
  async () => {
    const response = await _getUnreadMsgCount();
    return response.data;
  }
);

export const getConversationList = createAsyncThunk(
  "messaging/getConversationList",
  async () => {
    const response = await _getConversationList();
    return response.data;
  }
);

export const getConversationDetails = createAsyncThunk(
  "messaging/getConversationDetails",
  async (id) => {
    const response = await _getConversationDetails(id);
    return response.data;
  }
);

//POST
export const beginConversation = createAsyncThunk(
  "messaging/beginConversation",
  async (data) => {
    const response = await _beginConversation(data);
    return response.data;
  }
);

export const sendMessage = createAsyncThunk(
  "messaging/sendMessage",
  async (data) => {
    const response = await _sendMessage(data);
    return response.data;
  }
);

export const messageSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    resetChatbox(state) {
      state.conversationDetails.data = null;
    },
  },
  extraReducers: (builder) => {
    //GET

    //get unread messages
    builder
      .addCase(getUnreadMsgCount.pending, (state) => {
        state.unreadMsgCount.isLoading = true;
      })
      .addCase(getUnreadMsgCount.fulfilled, (state, { payload }) => {
        state.unreadMsgCount.isLoading = false;
        state.unreadMsgCount.data = payload;
      })
      .addCase(getUnreadMsgCount.rejected, (state, { payload }) => {
        state.unreadMsgCount.isLoading = false;
      });

    //get conversation list
    builder
      .addCase(getConversationList.pending, (state) => {
        state.conversationList.isLoading = true;
      })
      .addCase(getConversationList.fulfilled, (state, { payload }) => {
        state.conversationList.isLoading = false;
        state.conversationList.data = payload;
      })
      .addCase(getConversationList.rejected, (state, { payload }) => {
        state.conversationList.isLoading = false;
      });

    //conversation details
    builder
      .addCase(getConversationDetails.pending, (state) => {
        state.conversationDetails.isLoading = true;
      })
      .addCase(getConversationDetails.fulfilled, (state, { payload }) => {
        state.conversationDetails.isLoading = false;
        state.conversationDetails.data = payload;
      })
      .addCase(getConversationDetails.rejected, (state, { payload }) => {
        state.conversationDetails.isLoading = false;
      });
  },
});

export const getMessagingState = (state) => state.messaging;
export const { resetChatbox } = messageSlice.actions;
export default messageSlice.reducer;
