import React from "react";

//assets
import box from "assets/svg/box.svg";
import sort from "assets/svg/sort.svg";
import filter from "assets/svg/filter.svg";
import priceTag from "assets/svg/priceTag.svg";
import arrowDown from "assets/svg/arrowDown.svg";
import locationPin from "assets/svg/locationPin.svg";

export default function Filters() {
  return (
    <section className="flex flex-col items-start justify-between my-5 lg:items-center lg:flex-row gap-y-4">
      {/*  */}

      <section className="overflow-hidden overflow-x-scroll max-w-[100vw]  hide-scrollbar">
        <section className="flex items-center gap-x-4 w-[150%]  lg:w-full">
          <div className="flex items-center font-medium cursor-pointer gap-x-1 text-blue200">
            <img src={filter} alt="filter" />
            <p>Filter</p>
          </div>

          <div className="flex items-center justify-between w-40 px-4 py-2 text-sm font-medium border rounded-full cursor-pointer">
            <div className="flex items-center gap-x-2">
              <img src={locationPin} alt="location" />
              <p>Location</p>
            </div>

            <img src={arrowDown} alt="" className="w-3 mt-1" />
          </div>

          <div className="flex items-center justify-between w-32 px-4 py-2 text-sm font-medium border rounded-full cursor-pointer">
            <div className="flex items-center gap-x-2">
              <img src={priceTag} alt="price tag" />
              <p>Price</p>
            </div>

            <img src={arrowDown} alt="" className="w-3 mt-1" />
          </div>

          {/* <div className="flex items-center justify-between w-48 px-4 py-2 text-sm font-medium border rounded-full cursor-pointer">
            <div className="flex items-center gap-x-2">
              <img src={box} alt="Item condition" />
              <p>Item condition</p>
            </div>

            <img src={arrowDown} alt="" className="w-3 mt-1" />
          </div> */}
        </section>
      </section>

      {/*  */}

      <section className="flex items-center gap-x-4 mt-[-20px]">
        <div className="flex items-center font-medium cursor-pointer gap-x-2 text-blue200">
          <img src={sort} alt="sort" />
          <p>Sort</p>
        </div>

        <div className="flex items-center justify-between px-4 py-2 text-sm font-medium rounded-md cursor-pointer w-36 bg-grey50 text-blue200">
          <div className="flex items-center gap-x-2">
            <p>Newest first</p>
          </div>

          <img src={arrowDown} alt="" className="w-3 mt-1" />
        </div>
      </section>
    </section>
  );
}
