//libraries
import axios from "axios";

//utils
import { baseURL } from "utils/baseUrl";
import { errorToast } from "utils/toastUtil";
import { getToken } from "utils/localStorageServices";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  // timeout: 1000,
});

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (getToken()) {
      config.headers["Authorization"] = `Bearer ${getToken() ?? ""}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    let errorStatus = error?.response?.status;
    const errorList = error?.response?.data?.errors;
    let requestMsg =
      error?.response?.data?.message ??
      error.response?.data?.title ??
      error?.message;
    let logoutMsg = "Full authentication is required to access this resource";

    if (
      errorStatus === 401 ||
      (errorStatus === 403 && requestMsg === logoutMsg)
    ) {
      errorToast("Session Expired");
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    if (requestMsg === "One or more validation errors occurred.") {
      for (let error in errorList) {
        errorToast(errorList[error][0]);
      }
    } else {
      errorToast(requestMsg);
    }

    return Promise.reject(error);
  }
);

export default instance;
