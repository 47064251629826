import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//assets
import plus from "assets/svg/plus.svg";
import search from "assets/svg/search.svg";
import bannerImg from "assets/svg/bannerImg.svg";
import bannerImg2 from "assets/svg/bannerImg2.svg";
import bannerImg3 from "assets/svg/bannerImg3.svg";
import locationPin from "assets/svg/locationPin.svg";

//slices
import { getProducts } from "store/Product/productSlice";

export default function Banner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //usestate
  const [searchVal, setSearchVal] = useState(null);

  //functions
  const findProduct = (e) => {
    e.preventDefault();
    navigate(`/search-result?PN=${searchVal}`);
  };

  //styles
  const linearGrade = {
    background: "linear-gradient(263.59deg, #143D59 -3.23%, #3180B7 73.86%)",
  };

  const smImage = {
    background: `url(${bannerImg3})`,
  };

  const tags = {
    border: " 0.2px solid",
    borderImageSource:
      "linear-gradient(147.14deg, #FFFFFF 16.55%, #143D59 131.27%)",
    background:
      "linear-gradient(77.8deg, rgba(255, 255, 255, 0.25) -35%, rgba(255, 255, 255, 0.0875) 102.36%)",
  };
  return (
    <>
      {/* mobile screen */}
      <section
        className="lg:hidden md:hidden block  overflow-hidden h-[360px] "
        style={smImage}
      >
        <section className="w-[90%] mx-auto">
          <div>
            <h1 className="mt-10 text-3xl font-bold text-white w-[70%]">
              The easiest way to buy and sell used items
            </h1>

            <p className="w-[65%] text-sm mt-2 text-white">
              Lorem ipsum dolor sit amet consectetur. Phasellus quis volutpat
            </p>
          </div>

          {/* search */}
          <div className="my-4">
            <div className="flex items-center px-2 bg-white border rounded gap-x-2 ">
              <img src={search} alt="search box" />
              <input
                type="text"
                className="w-full py-2 outline-none"
                placeholder="Search"
              />
            </div>
          </div>
          {/*  */}

          {/* tags */}
          <div className="flex items-center gap-x-4">
            <div
              style={tags}
              className="px-4 py-2 text-sm text-white rounded-full"
            >
              {" "}
              Microwave
            </div>

            <div
              style={tags}
              className="px-4 py-2 text-sm text-white rounded-full"
            >
              {" "}
              Microwave
            </div>

            <div
              style={tags}
              className="px-4 py-2 text-sm text-white rounded-full"
            >
              {" "}
              Microwave
            </div>
          </div>
        </section>
      </section>
      {/*  */}

      {/* large screen */}
      <section className="relative items-center justify-center hidden lg:flex md:flex">
        <section
          className="flex justify-between w-full overflow-hidden rounded-2xl lg:h-[290px]"
          style={linearGrade}
        >
          <section className="flex h-full pl-20 ">
            <div className="flex flex-col items-start justify-center h-full">
              <h1 className="my-4 text-3xl font-bold text-white">
                The easiest way to buy <br />
                and sell used items
              </h1>
              <button className="flex items-center px-4 py-2 font-semibold rounded-full bg-primary text-blue200 gap-x-2">
                <img src={plus} alt="start selling" />
                <span>Start selling</span>
              </button>
            </div>
          </section>

          <section className="flex lg:h-[300px]">
            <img src={bannerImg} alt="start selling" />
            <img
              src={bannerImg2}
              alt="start selling"
              className="lg:ml-[-80px]"
            />
          </section>
        </section>

        {/* search box */}
        <form
          onSubmit={findProduct}
          className="absolute bottom-[-30px] flex bg-white shadow-lg rounded-full w-[60%]  px-4 py-3  text-sm"
        >
          <div className="flex items-center w-1/2  border-r-[2px] gap-x-2">
            <img src={search} alt="search box" />
            <input
              type="text"
              onChange={(e) => setSearchVal(e.target.value)}
              className="w-full outline-none"
              placeholder="What are you looking for?"
            />
          </div>

          <div className="flex items-center w-[40%] pl-2 gap-x-2">
            <img src={locationPin} alt="search box" />
            <input
              type="text"
              className="w-full outline-none"
              placeholder="Location"
            />
          </div>
          <button className="flex items-center px-6 py-2 text-sm font-semibold rounded-full gap-x-2 text-blue200 bg-primary">
            Search
          </button>
        </form>
        {/*  */}
      </section>

      {/*  */}
    </>
  );
}
