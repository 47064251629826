import React from "react";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//assets
import box from "assets/svg/box.svg";
import star from "assets/svg/star.svg";
import shield from "assets/svg/shield.svg";
import avatar from "assets/svg/avatar2.svg";
import sendMsg from "assets/svg/sendMsg.svg";
import eye from "assets/svg/showPassword.svg";

export default function Description({ product }) {
  const { seller } = product;
  return (
    <section>
      {/* trader name */}
      <FlexContainer extraClass="px-4 border py-2 rounded-full [ lg:flex hidden ]">
        <div className="flex items-center gap-x-2">
          <div className="relative">
            <img src={avatar} alt="" className="w-[40px]" />
            <div className="absolute right-0 w-3 h-3 bg-green-500 rounded-full bottom-1"></div>
          </div>
          <p className="font-semibold">{seller.name}</p>
        </div>

        <div className="flex items-center font-semibold text-grey70">
          <div className="flex items-center">
            {/* <p>{seller.rating}</p>
            <img src={star} alt="" /> */}
            <p>
              {/* (38) |  */}
              250 sales
            </p>
          </div>
        </div>
      </FlexContainer>
      {/*  */}

      {/* description */}
      <section className="pb-6 my-6 border-b">
        <h5 className="font-semibold text-blue200">Description</h5>
        <p className="my-4 text-sm">{product.description}</p>
        <div className="flex gap-x-2 [  text-sm ]">
          {product.tags.map((tag, index) => {
            return (
              <div
                key={index}
                className="px-4 py-1 font-semibold bg-grey50 text-blue200"
              >
                {tag.tagName}
              </div>
            );
          })}
        </div>
      </section>
      {/*  */}

      {/* seller's details */}
      <section className="pb-6 my-6 border-b [ lg:hidden block ]">
        <section className="mb-2 ">
          <div className="flex gap-x-2">
            <img src={box} alt="" />
            <h5 className="font-semibold text-blue200">Seller info</h5>
          </div>
        </section>

        <section className="flex items-center justify-between px-4 rounded-lg bg-grey50">
          <div className="flex items-center gap-x-2">
            <div className="relative">
              <img src={avatar} alt="" className="w-[40px]" />
              <div className="absolute right-0 w-3 h-3 bg-green-500 rounded-full bottom-1"></div>
            </div>

            <div className="flex flex-col text-xs gap-y-1">
              <p className="font-semibold">{seller.name}</p>
              <div className="flex items-center font-semibold text-grey70">
                <div className="flex items-center">
                  <p>{seller.rating}</p>
                  <img src={star} alt="" />
                  <p>(38) | 250 sales</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img src={sendMsg} alt="" />
          </div>
        </section>
      </section>
      {/*  */}

      {/* shipping*/}
      <section className="pb-6 my-6 border-b">
        {/* <div className="flex justify-between">
          <div className="flex gap-x-2">
            <img src={box} alt="" className="[ flex lg:hidden ]" />
            <h5 className="font-semibold text-blue200">Shipping</h5>
          </div>
          <p className="font-medium text-sm[ flex lg:hidden ]">From ₦5,000</p>
        </div>
        <FlexContainer extraClass="py-4">
          <div className="flex items-center gap-x-2">
            <img src={productBag} alt="" className="[ lg:flex hidden ] " />
            <p>Product will be shipped to your location</p>
          </div>

          <p className="font-medium [ lg:flex hidden ] ">From ₦5,000</p>
        </FlexContainer> */}

        <div className="flex items-center py-2 my-4 [ lg:px-4 px-2 ] gap-x-2 bg-yellow100">
          <img src={shield} alt="" />
          <div className="[ lg:px-4 ] py-1 font-semibold text-blue200 [ lg:text-sm text-xs ] ">
            Refund protection, secure payments and help whenever you need it.
          </div>
        </div>
      </section>
      {/*  */}

      {/* total views */}
      <FlexContainer>
        <p className="text-sm font-medium">Edited 1 hour ago</p>

        {/* <div className="flex items-center gap-x-1">
          <img src={eye} alt="" />
          <span className="text-sm"> 1.5k</span>
        </div> */}
      </FlexContainer>
      {/*  */}
    </section>
  );
}
