import React from "react";
import { useNavigate } from "react-router-dom";

export default function MobileNav({ title }) {
  const navigate = useNavigate();
  return (
    <section className="flex justify-between lg:hidden ">
      <div onClick={()=>navigate(-1)}>
        <i className="pi pi-arrow-left" style={{ color: "#143D59" }}></i>
      </div>
      <div className="flex items-center justify-center text-center ml-[-30px]">
        <h4 className="font-semibold text-blue200">{title}</h4>
      </div>
      <div></div>
    </section>
  );
}
