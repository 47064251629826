import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//assets
import star from "assets/svg/star.svg";
import avatar from "assets/svg/avatar.svg";

//utils
import { navLinks } from "./data";
import { getUserDetails } from "utils/localStorageServices";

export default function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //variables
  const { firstName } = getUserDetails();

  //functions
  const logoutHandler = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <section className="w-full h-[83vh] rounded-lg bg-blue200 shadow-lg">
      {/* user info */}
      <section className="pb-2 border-b border-grey10">
        <div className="flex justify-center ">
          <img src={avatar} alt="avatar" className="w-[2.5rem] mt-[-20px]" />
        </div>

        <div className="mt-2 text-center text-white">
          <h1 className="text-2xl font-medium">{firstName}</h1>

          <div className="flex justify-center mt-1 text-sm text-white gap-x-1">
            <img src={star} alt="" />
            <p>3.5/5.0</p>
          </div>
        </div>
      </section>
      {/*  */}

      {/* navlinks */}
      <section className="px-2 py-4">
        <div className="w-[80%] mx-auto flex flex-col justify-center gap-y-2">
          {navLinks.map((navlink, index) => {
            const { link, active, inactive, name } = navlink;
            const isActive = pathname === `/user/${link}`;
            return (
              <Link to={link} key={index}>
                <div
                  className={`flex items-center text-white gap-x-4 px-6 py-3 ${
                    isActive && "bg-[#FFFFFF26]  rounded-lg"
                  }`}
                >
                  <img
                    src={isActive ? active : inactive}
                    alt=""
                    className="w-4"
                  />
                  <p className="text-sm">{name}</p>
                </div>
              </Link>
            );
          })}

          <div
            onClick={logoutHandler}
            className="flex items-center cursor-pointer text-white gap-x-4 w-[80%] mx-auto px-2 mt-2 "
          >
            <i className="pi pi-sign-out" style={{ fontSize: "1rem" }}></i>
            <p className="text-sm">Logout</p>
          </div>
        </div>
      </section>
      {/*  */}
    </section>
  );
}
