import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//services
import { _getProvince, _updateProfile } from "services/profile-service";

const initialState = {
  provinceList: {
    isLoading: true,
    data: null,
  },
};

//GET
export const getProvince = createAsyncThunk(
  "profile/getProvince",
  async (city) => {
    const response = await _getProvince(city);

    return response.data;
  }
);

//PATCH
export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (data) => {
    const response = await _updateProfile(data);
    return response.data;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    //GET
    //province list
    builder
      .addCase(getProvince.pending, (state) => {
        state.provinceList.isLoading = true;
      })
      .addCase(getProvince.fulfilled, (state, { payload }) => {
        state.provinceList.isLoading = false;
        state.provinceList.data = payload;
      })
      .addCase(getProvince.rejected, (state, { payload }) => {
        state.provinceList.isLoading = false;
      });
  },
});

export const getProfileState = (state) => state.profile;
export default profileSlice.reducer;
