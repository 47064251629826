import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

//assets
import info from "assets/svg/info.svg";
// import charge from "assets/svg/charge.svg";
// import plus from "assets/svg/whitePlus.svg";
import withdraw from "assets/svg/withdraw.svg";
import tlWallet from "assets/svg/tl-wallet.svg";
import blWallet from "assets/svg/bl-wallet.svg";
import brWallet from "assets/svg/br-wallet.svg";

//slices
import { getWalletBalance, getWalletState } from "store/Wallet/walletSlice";

//components
import Loader from "components/UI/Loader";
import MobileNav from "components/pages/UserDashboard/Layout/MobileNav";

export default function Wallet() {
  const dispatch = useDispatch();
  const { walletBalance } = useSelector(getWalletState);

  //useeffect
  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  //variables
  const bgImg = {
    background:
      "radial-gradient(398.95% 3049.11% at 44.31% 36.81%, rgba(30, 90, 131, 0.93) 0%, #0B2B41 68.62%)",
  };

  const whiteGD = {
    background:
      "linear-gradient(274.15deg, rgba(255, 255, 255, 0.21) -9.23%, rgba(255, 255, 255, 0.17) 114.4%)",
  };

  return (
    <section>
      {walletBalance.isLoading && <Loader />}

      {!walletBalance.isLoading && (
        <section className="lg:px-4 py-4 lg:border rounded-lg min-h-[82vh]">
          <div>
            <h2 className="hidden text-lg font-semibold text-blue200 lg:block">
              Wallet
            </h2>
            <MobileNav title={"Wallet"} />
          </div>

          {/* verify account */}
          <section className="flex-col px-4 py-3 mt-4 rounded-md [ flex lg:flex-between lg:flex-row flex-col lg:gap-y-0 gap-y-2 ] lg:bg-grey50 bg-yellow100">
            <div className="flex items-center text-sm gap-x-2">
              <img src={info} alt="" className="w-[1.25rem] " />
              <p className="text-xs lg:text-sm">
                Verify account to be able to withdraw to your prefered account
              </p>
            </div>

            <button className="px-4 w-fit py-2 [ ml-[10%] lg:ml-0  ] text-xs font-semibold rounded-md text-blue200 bg-primary">
              Verify account
            </button>
          </section>
          {/*  */}

          {/* wallet balance */}
          <section
            className="flex-between relative w-full px-6 lg:h-[9.8125rem] h-[5.5625rem] mt-4 rounded-lg walletTL "
            style={bgImg}
          >
            <img
              src={tlWallet}
              alt=""
              className="absolute top-0 left-0 [ lg:w-64 w-24 ]"
            />
            <img
              src={blWallet}
              alt=""
              className="absolute bottom-0 left-0 [ lg:w-64 w-24 ]"
            />
            <img
              src={brWallet}
              alt=""
              className="absolute bottom-0 right-0 [ lg:w-64 w-24 ]"
            />
            <div>
              <h2 className="font-semibold text-white lg:text-[2.4rem] text-2xl">
                ₦ {walletBalance.data?.balance}
              </h2>
            </div>

            {/* <div className="flex items-center gap-x-2">
              <div
                className="flex px-4 py-3 text-xs text-white rounded-full gap-x-2 [ lg:text-base text-sm font-semibold ]"
                style={whiteGD}
              >
                <img src={withdraw} alt="" />
                <p>Withdraw</p>
              </div>

              <div
                className="flex px-4 py-3 text-xs text-white rounded-full gap-x-2"
                style={whiteGD}
              >
                <img src={plus} alt="" />
                <p>Fund account</p>
              </div>

              <div className="flex items-center px-4 py-3 text-xs text-white rounded-full gap-x-2">
                <img src={charge} alt="" />
                <p>Charge</p>
              </div>
            </div> */}
          </section>
          {/*  */}

          <section className="mt-4">
            <p className="font-semibold text-blue200 [ lg:text-lg text-sm ]">
              Transaction History
            </p>
          </section>
        </section>
      )}
    </section>
  );
}
