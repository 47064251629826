import React, { useState } from "react";
import axios from "axios";

//component
import CustomLoader from "components/UI/Loader/CustomLoader";
import MobileNav from "components/pages/UserDashboard/Layout/MobileNav";
import Notification from "components/pages/UserDashboard/Settings/Notification";
import UpdateProfile from "components/pages/UserDashboard/Settings/UpdateProfile";

//utils
import { baseURL } from "utils/baseUrl";
import { errorToast, successToast } from "utils/toastUtil";
import { getUserDetails } from "utils/localStorageServices";

export default function Settings() {
  //usestate
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { email } = getUserDetails() ?? {};

    axios
      .post(`${baseURL}/users/forgot_password`, { email })
      .then((res) => {
        successToast("An Email has been sent to you ");
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="px-6 py-4 lg:border rounded-lg min-h-[82vh]">
      {/* tab */}
      <section className="">
        <div>
          <div className="hidden flex-between lg:flex">
            <h2 className="text-lg font-semibold text-blue200">Settings</h2>
            <div
              className={`center-content  px-4 py-2 border rounded-full bg-blue200 text-white cursor-pointer`}
              onClick={forgotPassword}
            >
              {isLoading ? <CustomLoader color="white" /> : "Reset Password"}
            </div>
          </div>
          <MobileNav title={"Settings"} />
        </div>

        <section className="flex text-sm gap-x-4">
          <div className="w-full mt-2 center-content">
            <div
              className={`center-content lg:hidden  px-4 py-2 border rounded-full bg-blue200 text-white cursor-pointer`}
              onClick={forgotPassword}
            >
              Reset Passowrd
            </div>
          </div>

          {/* <div
            className={` ${
              tab === 1 && "bg-blue200 text-white"
            } px-4 py-2 border rounded-full cursor-pointer`}
            onClick={() => setTab(1)}
          >
            Update profile
          </div>
          <div
            className={` ${
              tab === 0 && "bg-blue200 text-white"
            } px-4 py-2 border rounded-full cursor-pointer`}
            onClick={() => setTab(0)}
          >
            Security
          </div>

          <div
            className={` ${
              tab === 2 && "bg-blue200 text-white"
            } px-4 py-2 border rounded-full cursor-pointer`}
            onClick={() => setTab(2)}
          >
            Notification
          </div> */}
        </section>
      </section>
      {/*  */}

      <section className="my-8">
        {tab === 1 && <UpdateProfile />}

        {tab === 2 && <Notification />}
      </section>
    </section>
  );
}
