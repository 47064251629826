import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

//assets
import leftArrow from "assets/svg/leftArrow.svg";
import locationPin from "assets/svg/locationPin2.svg";

//slices
import { beginOrder } from "store/Order/orderSlice";

//libraries
import { Modal } from "antd";
import { ClipLoader } from "react-spinners";
import { PaystackConsumer } from "react-paystack";

//utils
import { errorToast } from "utils/toastUtil";
import { formatAmount } from "utils/formatter";
import { getUserDetails } from "utils/localStorageServices";

//component
import PaymentSuccessful from "../Modals/PaymentSuccessful";

export default function BuyNowDrawer({ product, closeDrawer, chatSeller }) {
  const userDetails = getUserDetails();
  const { email, address } = userDetails ?? {};
  const { photos, name, price } = product;

  const dispatch = useDispatch();

  //useRef
  const addressRef = useRef();
  const paymentButtonRef = useRef();
  const serverOrderCode = useRef(null);

  // usestate
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [useDefaultAddress, setUseDefaultAddress] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [transactionCodes, setTransactionCodes] = useState({
    orderCode: null,
    paymentCode: null,
    totalAmount: 0,
  });
  const [config, setConfig] = useState(null);
  //useeffect
  useEffect(() => {
    if (isUpdate) {
      addressRef.current.focus();
    }
  }, [isUpdate]);

  useEffect(() => {
    address && setUseDefaultAddress(true);
  }, [address]);

  useEffect(() => {
    const { paymentCode, orderCode, totalAmount } = transactionCodes ?? {};
    if (paymentCode && orderCode) {
      let config = {
        email: email,
        reference: paymentCode,
        amount: totalAmount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      };
      setConfig(config);
    }
  }, [transactionCodes, email, price]);

  // payment

  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    console.log(reference, config);
    setTransactionCodes({
      orderCode: null,
      paymentCode: null,
    }); //
    setConfig(null);
    setIsPaymentModalOpen(true);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    console.log("closed");
  };

  //

  const componentProps = {
    ...config,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  useEffect(() => {
    if (config) {
      console.log(componentProps, "componentProps");
      paymentButtonRef.current.click();
    }
  }, [config]);

  //function
  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const triggerOrder = () => {
    let finalAddress = addressRef.current?.value ?? address;

    if (!finalAddress) {
      errorToast("Enter an address");
      return;
    }

    const data = {
      address: finalAddress,
      productId: product?.id,
    };

    setIsLoading(true);
    dispatch(beginOrder(data)).then(({ type, payload }) => {
      setIsLoading(false);
      if (type.includes("fulfilled")) {
        const { paymentCode, orderCode, totalAmount } = payload ?? {};
        serverOrderCode.current = orderCode;
        setTransactionCodes({
          orderCode,
          totalAmount,
          paymentCode,
        }); //

        console.log(config);
      }
    });
  };

  return (
    <section className="py-4 ">
      <section className="flex items-center w-full text-center ">
        <img src={leftArrow} alt="" />

        <p className="w-11/12 text-lg font-semibold text-center">
          Select delivery method
        </p>
      </section>

      <section className="my-8">
        {/* my address  */}
        {useDefaultAddress ? (
          <section className="flex items-center justify-between w-full p-4 mt-4 mb-4 border-2 rounded-md border-b-blue200">
            <div className="flex gap-x-2">
              <img src={locationPin} alt="" />
              <p className="font-medium">My address</p>
            </div>

            <div className="flex gap-x-2">
              <p className=" text-blue30">From N5,000</p>
              <input
                type="checkbox"
                checked={useDefaultAddress}
                onChange={() => setUseDefaultAddress(!useDefaultAddress)}
              />
            </div>
          </section>
        ) : (
          <>
            {/* update address  */}
            <section className="flex items-center justify-between mt-2">
              <section className="w-9/12">
                {!isUpdate ? (
                  <div>
                    <p className="text-gray110">{address}</p>
                  </div>
                ) : (
                  <input
                    type="text"
                    ref={addressRef}
                    defaultValue={address}
                    placeholder="Enter delivery address"
                    className="w-full py-3 pl-1 border-b outline-none"
                  />
                )}
              </section>

              <section>
                {!isUpdate ? (
                  <button
                    onClick={() => setIsUpdate(true)}
                    className="text-sm font-medium underline text-blue200"
                  >
                    Update address
                  </button>
                ) : (
                  <button className="text-sm font-medium underline text-blue200">
                    Confirm
                  </button>
                )}
              </section>
            </section>

            {/*  */}
          </>
        )}
        {/*  */}

        {/* product card  */}
        <section className="absolute w-full py-4 border-t bottom-16">
          <section className="w-full bg-white border-t rounded-lg shadow ">
            <div className="flex items-center px-2 py-3 gap-x-4 ">
              <div>
                <img
                  src={photos?.[0]?.url}
                  alt=""
                  className="w-[57px] h-[57px] rounded-lg"
                />
              </div>

              <div className="flex flex-col gap-y-1">
                <h3 className="text-sm font-semibold text-grey120">{name}</h3>

                <h2 className="font-medium text-blue30">
                  {formatAmount(price)}
                </h2>
              </div>
            </div>
          </section>
        </section>

        {/*  */}

        {/* continue button  */}
        <section className="absolute w-full bottom-4">
          <button
            onClick={triggerOrder}
            className="w-[90%] justify-center items-center text-white py-4 flex gap-x-2 border-t rounded-full bg-secondary bottom-10"
          >
            {isLoading ? (
              <ClipLoader color="white" size={12} />
            ) : (
              <>
                {" "}
                Continue
                <i className="text-white pi pi-arrow-right"></i>
              </>
            )}
          </button>
        </section>

        <PaystackConsumer {...componentProps}>
          {({ initializePayment }) => (
            <button
              className="hidden"
              ref={paymentButtonRef}
              onClick={() =>
                initializePayment(
                  handlePaystackSuccessAction,
                  handlePaystackCloseAction
                )
              }
            >
              Paystack Consumer Implementation
            </button>
          )}
        </PaystackConsumer>

        {/*  */}
      </section>

      <>
        <Modal
          destroyOnClose={true}
          open={isPaymentModalOpen}
          onCancel={closePaymentModal}
          footer={null}
          modalRender={(modal) => {
            return React.cloneElement(modal, {
              style: {
                ...modal.props.style,
                ...{ padding: 0 },
              },
            });
          }}
        >
          <PaymentSuccessful
            chatSeller={chatSeller}
            closeModal={closePaymentModal}
            serverOrderCode={serverOrderCode.current}
          />
        </Modal>
      </>
    </section>
  );
}
