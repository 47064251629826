import { Select } from "antd";
import Loader from "components/UI/Loader";
import CustomLoader from "components/UI/Loader/CustomLoader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileState,
  getProvince,
  updateProfile,
} from "store/Profile/profileSlice";
import { getUserDetails, localStorageSetter } from "utils/localStorageServices";
import { errorToast, successToast } from "utils/toastUtil";
import { searchOptions } from "utils/utilFunc";

export default function UpdateProfile() {
  const dispatch = useDispatch();

  //useselector
  const { provinceList } = useSelector(getProfileState);
  //usestate
  const [cityId, setCityId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [province, setProvince] = useState(null);
  const { firstName, lastName, email, phone, address } = profileData ?? {};

  //useffect
  useEffect(() => {
    const userDetails = getUserDetails();

    setProfileData(getUserDetails());
    setCityId(userDetails?.cityId);
    setProvince(userDetails?.province);
  }, []);

  useEffect(() => {
    cityId && dispatch(getProvince(cityId));
  }, [cityId, dispatch]);

  useEffect(() => {
    const { data } = provinceList ?? {};
    if (data) {
      setProvinces((prev) =>
        data.map((province) => {
          return { value: province.name, label: province.name };
        })
      );
    }
  }, [provinceList]);

  const submitHandler = (e) => {
    e.preventDefault();

    let { phone, address } = e.target;

    address = address?.value;
    phone = phone?.value;

    if (!cityId) {
      errorToast("Select city");
      return;
    }
    if (!province) {
      errorToast("Select province");
      return;
    }

    if (!address) {
      errorToast("Enter address");
      return;
    }
    if (!phone) {
      errorToast("Enter phone number");
      return;
    }
    const data = {
      phone,
      cityId,
      province,
      address,
    };

    setIsLoading(true);
    dispatch(updateProfile(data)).then(({ type, payload }) => {
      setIsLoading(false);
      if (type.includes("fulfilled")) {
        const newUserProfile = {
          firstName,
          lastName,
          email,
          cityId,
          phone,
          address,
          province,
        };
        localStorageSetter("user-details", newUserProfile);
        successToast("Profile updated successfully");
      }
    });
  };
  return (
    <section>
      <section className="flex justify-center w-full lg:mt-16">
        <form onSubmit={submitHandler} className="flex flex-col gap-y-4">
          <div className="flex w-full lg:items-center lg:gap-x-24 flex-responsive ">
            <label htmlFor="name" className="text-sm">
              {" "}
              Name
            </label>
            <div className="flex flex-responsive gap-x-2 [  lg:w-[400px] w-full lg:flex-row flex-col gap-y-2 ] ">
              <input
                disabled
                type="text"
                name="firstName"
                value={firstName || ""}
                placeholder="First name"
                className=" [ lg:w-1/2 w-full ]  border   py-2 cursor-not-allowed  px-2 rounded-md text-sm outline-none [ placeholder:text-sm placeholder:text-grey90 ]"
              />
              <input
                disabled
                type="text"
                name="lastName"
                value={lastName || ""}
                placeholder="Last name"
                className=" [ lg:w-1/2 w-full ]  border  py-2 cursor-not-allowed px-2 rounded-md text-sm outline-none [ placeholder:text-sm placeholder:text-grey90 ]"
              />
            </div>
          </div>

          <div className="flex w-full lg:items-center lg:gap-x-24 flex-responsive ">
            <label htmlFor="name" className="text-sm ">
              Email
            </label>

            <input
              disabled
              type="text"
              name="email"
              value={email || ""}
              placeholder="Enter shipping address"
              className="input-style lg:w-[400px] w-full"
            />
          </div>

          <div className="flex w-full lg:items-center flex-responsive gap-x-28 ">
            <label htmlFor="name" className="text-sm ">
              City
            </label>

            <Select
              showSearch
              value={cityId}
              className="lg:w-[400px] w-full h-9"
              placeholder="Select City"
              filterOption={searchOptions}
              onChange={(e) => setCityId(e)}
              options={[{ value: 1, label: "Lagos" }]}
            />
          </div>

          <div className="flex w-full lg:items-center flex-responsive gap-x-20 ">
            <label htmlFor="name" className="text-sm ">
              Province
            </label>

            <Select
              showSearch
              value={province}
              options={provinces}
              className="lg:w-[400px] w-full h-9"
              filterOption={searchOptions}
              placeholder="Select province"
              loading={provinceList.isLoading}
              onChange={(e) => setProvince(e)}
            />
          </div>

          <div className="flex w-full lg:items-center flex-responsive gap-x-20 ">
            <label htmlFor="name" className="text-sm ">
              Address
            </label>

            <textarea
              name="address"
              defaultValue={address || ""}
              className="input-style lg:w-[400px] w-full"
            ></textarea>
          </div>

          <div className="flex w-full lg:items-center flex-responsive lg:gap-x-8 ">
            <label htmlFor="name" className="text-sm">
              Phone number
            </label>

            <input
              type="text"
              name="phone"
              defaultValue={phone || ""}
              placeholder="Phone number"
              className="input-style lg:w-[400px] w-full"
            />
          </div>

          <button className="lg:w-[550px] py-2 rounded-full text-blue200 bg-primary">
            {isLoading ? <CustomLoader color="secondary" /> : "Save"}
          </button>
        </form>
      </section>
    </section>
  );
}
