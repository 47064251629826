import Axios from "lib/axiosInterceptor";

//get
export const _getUnreadMsgCount = () => {
  return Axios.get(`/chats/unread_message_count`);
};

export const _getConversationList = () => {
  return Axios.get(`/chats/conversations`);
};



export const _getConversationDetails = (conversationId) => {
  return Axios.get(`/chats/conversations/${conversationId}/messages`);
};

//post
export const _sendMessage = (data) => {
  return Axios.post(`/chats/messages`, data);
};

export const _beginConversation = (data) => {
  return Axios.post(`/chats/conversations`, data);
};

