import React from "react";

// import shoe from "assets/svg/shoe.svg";
//utils
import { formatAmount, toLowerCase } from "utils/formatter";

export default function OrderCard({ product }) {
  const { photos, name, status, price } = product;
  return (
    <section className="w-full bg-white rounded-lg shadow">
      <div className="flex items-center px-2 py-4 gap-x-3 ">
        <div>
          <img src={photos?.[0]?.url} alt="" className="w-16 rounded-lg" />
        </div>

        <div className="flex flex-col gap-y-1">
          <h3 className="text-sm font-semibold">{name}</h3>
          <div className="text-xs font-semibold text-center w-[5rem]">
            {toLowerCase(status) === "available" ? (
              <p className="px-2 py-1 text-green-700 capitalize bg-green-200 rounded">
                {status}
              </p>
            ) : (
              <p className="px-2 py-1 text-red-700 capitalize bg-red-200 rounded">
                {status}
              </p>
            )}
          </div>

          {/* <p className="w-[4.375rem] px-2 py-1 text-xs font-semibold text-black rounded bg-grey50">
            Canceled
          </p> */}
          <h2 className="font-medium text-blue30">{formatAmount(price)}</h2>
        </div>
      </div>
    </section>
  );
}
