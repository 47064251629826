import React from "react";

//libraries
import { Slider } from "antd";

export default function PriceRangeModal({ setPriceRange, applyFilter }) {
  //function
  const onChangeComplete = (value) => {
    setPriceRange(value);
  };
  return (
    <section>
      <h3 className="font-semibold text-blue200">What's your price range?</h3>

      <section className="my-4">
        <section className="flex items-center justify-between text-sm font-semibold">
          <p>Min</p>
          <p>Max</p>
        </section>
        <section>
          <Slider
            range
            max={1000000}
            defaultValue={[20, 50]}
            onChangeComplete={onChangeComplete}
          />
        </section>

        <button
          onClick={applyFilter}
          className="w-full py-2 mt-6 font-bold rounded-full bg-primary text-blue200"
        >
          Apply
        </button>
      </section>
    </section>
  );
}
