import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//services
import {
  _beginOrder,
  _getOrderHistory,
  _orderPaymentStatus,
} from "services/order-services";

const initialState = {
  orderHistory: {
    isLoading: false,
    data: null,
  },

  productDetails: {
    isLoading: false,
    data: null,
  },
};

//GET

export const getOrderHistory = createAsyncThunk(
  "order/getMyOrder",
  async (params) => {
    const response = await _getOrderHistory(params);
    return response.data;
  }
);

//POST
export const beginOrder = createAsyncThunk("order/beginOrder", async (data) => {
  const response = await _beginOrder(data);

  return response.data;
});

export const orderPaymentStatus = createAsyncThunk(
  "order/orderPaymentStatus",
  async (orderId) => {
    const response = await _orderPaymentStatus(orderId);

    return response.data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  extraReducers: (builder) => {
    //GET

    //order list
    builder
      .addCase(getOrderHistory.pending, (state) => {
        state.orderHistory.isLoading = true;
      })
      .addCase(getOrderHistory.fulfilled, (state, { payload }) => {
        state.orderHistory.isLoading = false;
        state.orderHistory.data = payload;
      })
      .addCase(getOrderHistory.rejected, (state, { payload }) => {
        state.orderHistory.isLoading = false;
      });
  },
});

export const getOrderstate = (state) => state.order;
export default orderSlice.reducer;
