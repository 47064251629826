import React from "react";

// components
import OrderCard from "./OrderCard.jsx";
import { useSelector } from "react-redux";
import { getProductState } from "store/Product/productSlice.js";

export default function Orderlist() {
  //useselector
  const { merchantProductList } = useSelector(getProductState);
  const products = merchantProductList.data?.products;

  return (
    <section className="[ lg:hidden flex flex-col gap-y-4  ] w-full mt-4 mb-20 px-2">
      {products?.map((product) => {
        return <OrderCard key={product.id} product={product} />;
      })}
    </section>
  );
}
