import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//assets
import app from "assets/svg/appliances.svg";
import right from "assets/svg/rightActiveCarousel.svg";
import left from "assets/svg/leftDisabledCarousel.svg";

//utils

//component
import ProductCard from "components/UI/Product/ProductCard";
import Banner from "components/pages/Website/Homepage/Banner";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//slices
import { getProducts, getProductState } from "store/Product/productSlice";
import {
  getCategories,
  getCategoriestate,
} from "store/Category/categorySlices";
import Loader from "components/UI/Loader";

export default function Homepage() {
  const dispatch = useDispatch();

  //useselectors
  const { productList } = useSelector(getProductState);
  const { categoryList } = useSelector(getCategoriestate);

  //usestates
  const [activeCate, setActiveCate] = useState("");

  //useeffect
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      category_id: activeCate,
    };
    dispatch(getProducts(params));
  }, [dispatch, activeCate]);

  return (
    <>
      <section>
        {/* banner */}
        <Banner />
        {/*  */}

        <section className="lg:w-full w-[90%] mx-auto">
          {/* top categories */}
          <section className="hidden my-16 lg:block">
            {/* category list */}
            <section>
              <FlexContainer>
                <h2 className="text-xl font-semibold text-blue200">
                  Top categories
                </h2>
              </FlexContainer>
            </section>

            <section className="flex my-6 overflow-x-scroll gap-x-4 lg:gap-x-6 hide-scrollbar">
              {categoryList?.data?.length > 0 ? (
                <section className="flex">
                  {/* all categories */}
                  <section className="min-w-[150px] ">
                    <div
                      onClick={() => setActiveCate("")}
                      className={`flex cursor-pointer flex-col justify-center  py-6  rounded-lg gap-y-4 w-full ${
                        activeCate === "" && "bg-[#143D590D]"
                      } `}
                    >
                      <div className="flex justify-center ">
                        <img src={app} alt="" />
                      </div>
                      <p className="w-full text-sm font-medium text-center ">
                        All
                      </p>
                    </div>
                  </section>
                   {/*  */}

                   {/* category list */}
                  {categoryList?.data?.map((category) => {
                    return (
                      <section key={category?.id} className="min-w-[180px] ">
                        <div
                          onClick={() => setActiveCate(category?.id)}
                          className={`flex cursor-pointer flex-col justify-center  py-6  rounded-lg gap-y-4 w-full ${
                            activeCate === category?.id && "bg-[#143D590D]"
                          } `}
                        >
                          <div className="flex justify-center ">
                            <img src={category?.icon ?? app} alt="" />
                          </div>
                          <p className="w-full text-sm font-medium text-center ">
                            {category?.name}
                          </p>
                        </div>
                      </section>
                    );
                  })}
                   {/*  */}
                </section>
              ) : (
                <div className="flex items-center justify-center w-full text-center">
                  <p className="font-bold text-blue200">
                    No category available{" "}
                  </p>
                </div>
              )}
            </section>
            {/*  */}
          </section>
          {/*  */}

          {/* product list */}

          {(productList.isLoading || categoryList.isLoading) && <Loader />}

          {!productList.isLoading && !categoryList.isLoading && (
            <section className="my-8 lg:my-16">
              <section>
                <FlexContainer>
                  <h2 className="text-lg font-semibold lg:text-xl text-blue200">
                    The best, at the best price
                  </h2>

                  <div className="hidden lg:flex gap-x-4">
                    <img
                      src={left}
                      alt="previous"
                      className="w-10 cursor-not-allowed"
                    />

                    <img
                      src={right}
                      alt="next"
                      className="w-10 cursor-pointer"
                    />
                  </div>
                </FlexContainer>
              </section>

              {/* products */}
              <section className="grid grid-cols-2 my-6 lg:grid-cols-4 gap-x-4 lg:gap-x-10 gap-y-4">
                {productList?.data?.products?.length > 0 &&
                  productList?.data?.products?.map((product, index) => {
                    return <ProductCard product={product} key={index} />;
                  })}
              </section>

              {productList?.data?.products?.length < 1 && (
                <p className="w-full my-10 font-semibold text-center">
                  No product found
                </p>
              )}
              {/*  */}
            </section>
          )}

          {/*  */}
        </section>

        {/*  */}
      </section>
    </>
  );
}
