import Axios from "lib/axiosInterceptor";

//get


export const _getMerchantProducts = (params) => {
  return Axios.get(`/products/my_products`, {
    params:{
      ...params
    }
  });
};

export const _getProducts = (params) => {
  const product_name = params?.product_name ?? "";
  const category_id = params?.category_id ?? "";
  const max_amount = params?.max_amount ?? "";
  const min_amount = params?.min_amount ?? "";
  return Axios.get(
    `/products?product_name=${product_name}&category_id=${category_id}&max_amount=${max_amount}&min_amount=${min_amount}`
  );
};

export const _getProductDetails = (id) => {
  return Axios.get(`/products/${id}`);
};

//post
export const _createProduct = (data) => {
  return Axios.post(`/products`, data);
};

//patch
export const _editProduct = (data) => {
  return Axios.patch(`/products`, data);
};
