import { createBrowserRouter } from "react-router-dom";

//components
import Login from "views/Authentication/Login";
import CategoryPage from "views/Website/Category";
import SearchPage from "views/Website/SearchPage";
import Register from "views/Authentication/Register";
import ProductDetails from "views/Website/ProductDetails";
import ResetPassword from "views/Authentication/ResetPassword";
import ChangePassword from "views/Authentication/ChangePassword";
import WebsiteLayout from "components/pages/Website/Layout/WebsiteLayout";
import AuthLayout from "components/pages/Authentication/AuthLayout";

//user dashboard
import Shopping from "views/UserDashboard/Shopping";
import UserDashboardLayout from "components/pages/UserDashboard/Layout";
import Sales from "views/UserDashboard/Sales";
import Products from "views/UserDashboard/Products";
import AddProduct from "views/UserDashboard/Products/AddProduct";
import Message from "views/UserDashboard/Message";
import Favourite from "views/UserDashboard/Favourite";
import Wallet from "views/UserDashboard/Wallet";
import Settings from "views/UserDashboard/Settings";
import Homepage from "views/Website/Homepage";

import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import EditProduct from "views/UserDashboard/Products/EditProduct";
import EmailVerification from "views/Authentication/EmailVerification";
import MessageInbox from "components/pages/UserDashboard/Message/MobileScreen/MessageInbox";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <AuthRoute>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </AuthRoute>
    ),
  },

  {
    path: "/register",
    element: (
      <AuthRoute>
        <AuthLayout>
          <Register />
        </AuthLayout>
      </AuthRoute>
    ),
  },

  {
    path: "/reset-password",
    element: (
      <AuthRoute>
        <AuthLayout>
          <ResetPassword />
        </AuthLayout>
      </AuthRoute>
    ),
  },

  {
    path: "/api/v1/users/reset_password",
    element: (
      <AuthRoute>
        <AuthLayout>
          <ChangePassword />
        </AuthLayout>
      </AuthRoute>
    ),
  },

 

  {
    path: "/",
    element: (
      <WebsiteLayout>
        <Homepage />
      </WebsiteLayout>
    ),
  },
  {
    path: "/category",
    element: (
      <WebsiteLayout>
        <CategoryPage />
      </WebsiteLayout>
    ),
  },

  {
    path: "/search-result",
    element: <SearchPage />,
  },

  {
    path: "/product/:id",
    element: (
      <WebsiteLayout>
        <ProductDetails />
      </WebsiteLayout>
    ),
  },

  {
    path: "/api/v1/users/verify_email",
    element: (
      <ProtectedRoute>
        <EmailVerification />
        </ProtectedRoute>
    ),
  },

  {
    path: "/user",
    element: (
      <ProtectedRoute>
        <UserDashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        path: "shopping",
        element: <Shopping />,
      },

      {
        path: "sales",
        element: <Sales />,
      },

      {
        path: "products",
        element: <Products />,
      },

      {
        path: "add-product",
        element: <AddProduct />,
      },

      {
        path: "edit-product/:productId",
        element: <EditProduct />,
      },
      {
        path: "messages",
        element: <Message />,
      },

      { path: "message/:conversationId",
        element: <MessageInbox />,
      },
      {
        path: "favorites",
        element: <Favourite />,
      },
      {
        path: "wallet",
        element: <Wallet />,
      },

      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
]);
