import React from "react";

//assets
import star from "assets/svg/star.svg";
import avatar from "assets/svg/avatar2.svg";

export default function Profiles() {
  return (
    <section>
      <section className="grid grid-cols-3 gap-x-8 gap-y-4">
        <section className="flex items-center px-4 py-3 border rounded-lg gap-x-3">
          <div>
            <img src={avatar} alt="" className="w-[3rem]" />
          </div>

          <div className="w-11/12">
            <h2 className="text-lg font-semibold">Jerry.O</h2>
            <div className="flex items-center font-semibold text-grey70">
              <div className="flex items-center w-full text-sm">
                <p>3.5</p>
                <img src={star} alt="" />
                <div className="flex justify-between w-full ">
                  <p>(38 sales)</p>

                  <p>Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="flex items-center px-4 py-3 border rounded-lg gap-x-3">
          <div>
            <img src={avatar} alt="" className="w-[3rem]" />
          </div>

          <div className="w-11/12">
            <h2 className="text-lg font-semibold">Jerry.O</h2>
            <div className="flex items-center font-semibold text-grey70">
              <div className="flex items-center w-full text-sm">
                <p>3.5</p>
                <img src={star} alt="" />
                <div className="flex justify-between w-full ">
                  <p>(38 sales)</p>

                  <p>Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="flex items-center px-4 py-3 border rounded-lg gap-x-3">
          <div>
            <img src={avatar} alt="" className="w-[3rem]" />
          </div>

          <div className="w-11/12">
            <h2 className="text-lg font-semibold">Jerry.O</h2>
            <div className="flex items-center font-semibold text-grey70">
              <div className="flex items-center w-full text-sm">
                <p>3.5</p>
                <img src={star} alt="" />
                <div className="flex justify-between w-full ">
                  <p>(38 sales)</p>

                  <p>Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="flex items-center px-4 py-3 border rounded-lg gap-x-3">
          <div>
            <img src={avatar} alt="" className="w-[3rem]" />
          </div>

          <div className="w-11/12">
            <h2 className="text-lg font-semibold">Jerry.O</h2>
            <div className="flex items-center font-semibold text-grey70">
              <div className="flex items-center w-full text-sm">
                <p>3.5</p>
                <img src={star} alt="" />
                <div className="flex justify-between w-full ">
                  <p>(38 sales)</p>

                  <p>Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="flex items-center px-4 py-3 border rounded-lg gap-x-3">
          <div>
            <img src={avatar} alt="" className="w-[3rem]" />
          </div>

          <div className="w-11/12">
            <h2 className="text-lg font-semibold">Jerry.O</h2>
            <div className="flex items-center font-semibold text-grey70">
              <div className="flex items-center w-full text-sm">
                <p>3.5</p>
                <img src={star} alt="" />
                <div className="flex justify-between w-full ">
                  <p>(38 sales)</p>

                  <p>Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  );
}
