import React, { useState } from "react";

//assets
import Products from "components/pages/UserDashboard/Favorites/Products";
import Profiles from "components/pages/UserDashboard/Favorites/Profiles";
import Searches from "components/pages/UserDashboard/Favorites/Searches";

export default function Favourite() {
  //usestates
  const [tab, setTab] = useState(0);
  return (
    <section className="px-8 py-8 border rounded-lg">
      {/* tab */}
      <section className="flex justify-between">
        <div>
          <h3 className="text-lg font-semibold text-blue200">Favorites</h3>
        </div>

        <div className="flex text-sm ">
          <div
            className={` ${
              tab === 0 && "border-b-blue200 font-semibold"
            } border-b text-blue200 cursor-pointer `}
            onClick={() => setTab(0)}
          >
            <p className="px-6 py-2"> Products </p>
          </div>

          <div
            className={` ${
              tab === 1 && "border-b-blue200 font-semibold"
            } border-b text-blue200 cursor-pointer `}
            onClick={() => setTab(1)}
          >
            <p className="px-6 py-2"> Profiles </p>
          </div>

          <div
            className={` ${
              tab === 2 && "border-b-blue200 font-semibold"
            } border-b text-blue200 cursor-pointer `}
            onClick={() => setTab(2)}
          >
            <p className="px-6 py-2"> Searches </p>
          </div>
        </div>
      </section>
      {
              tab === 1 && (
                <div className="flex items-center text-sm gap-x-4">

                    <p>Sort by</p>

                    <div>
                        <select className="text-blue200" >
                            <option value=""> Location</option>
                        </select>
                    </div>

                    <div>
                        <select className="text-blue200" >
                            <option value=""> Recently added</option>
                        </select>
                    </div>

                </div>
              )
          }
      {/*  */}

      <section className="mt-6">{tab === 0 && <Products />}
      
      {tab === 1 && <Profiles />}

      {tab === 2 && <Searches />}
      </section>
    </section>
  );
}
