import React from "react";

//assets
import search from "assets/svg/greySearch.svg";
import inprogressImg from "assets/svg/inprogress.svg";

export default function MobileScreenNav() {
  const bgGradient = {
    background:
      "linear-gradient(43.09deg, #41A512 -2.16%, #41A512 42.24%, #53C31E 136.59%)",
  };
  return (
    <section className=" [ lg:hidden block ]">
      <section className="flex justify-between ">
        <div>
          <i className="pi pi-arrow-left" style={{ color: "#143D59" }}></i>
        </div>
        <div className="flex items-center justify-center text-center ml-[-30px]">
          <h4 className="font-semibold text-blue200">Products</h4>
        </div>
        <div></div>
      </section>
      {/* search */}
      <section className="w-full my-2">
        <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
          <img src={search} alt="search box" />
          <input
            type="text"
            className="w-full outline-none [ placeholder:text-xs ] text-xs"
            placeholder="Search "
          />
        </div>
      </section>
      {/*  */}

      {/* tabs */}
      <section className="[ flex items-center justify-between ] text-xs gap-x-3 my-3 [ text-blue200 font-medium ]">
        <div className=" [ px-6 py-2 ] text-center rounded-full bg-grey50">
          All
        </div>
        <div className="[ flex items-center gap-x-1 ] [ px-3 py-2 ] text-center rounded-full bg-grey50 ">
          <img src={inprogressImg} alt="" />
          <p>In progress</p>
        </div>

        <div
          className="flex items-center px-4 py-2 text-center text-white rounded-full gap-x-2"
          style={bgGradient}
        >
          <i
            className="pi pi-check"
            style={{ color: "white", fontSize: "12px" }}
          ></i>
          <p>Completed</p>
        </div>
      </section>
      {/*  */}
    </section>
  );
}
