import Axios from "lib/axiosInterceptor";

//get
export const _getProvince = (city) => {
  return Axios.get(`users/province_list?city=${city}`);
};

//patch
export const _updateProfile = (data) => {
  return Axios.patch(`/users/update_profile`, data);
};
