import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//utils
import { columns } from "./data";

//libraries
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

//slices
import { getOrderHistory, getOrderstate } from "store/Order/orderSlice";

//components
import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";

export default function SalesTable({ tab }) {
  const dispatch = useDispatch();
  //useselector
  const { orderHistory } = useSelector(getOrderstate);
  const { isLoading, data } = orderHistory ?? {};
  const { pagination = null, content = null } = data ?? {};

  //usestate
  const [pagObj, setPagObj] = useState({
    first: 0,
    rows: 1,
    page: 1,
    pageSize: 10,
  });

  //useeffect
  useEffect(() => {
    const params = {
      status: tab,
      page: pagObj.page,
      page_size: pagObj.pageSize,
    };
    dispatch(getOrderHistory(params));
  }, [pagObj, dispatch, tab]);

  return (
    <>
      {pagination && (
        <Pagination
          pagObj={pagObj}
          setPagObj={setPagObj}
          serverPag={pagination}
        />
      )}

      {isLoading && <Loader />}

      {!isLoading && (
        <section className="mt-4 space-y-6 bg-white rounded-lg">
          <section className=" [ lg:block hidden ]   ">
            <DataTable
              value={content}
              scrollable
              scrollHeight="400px"
              tableStyle={{ minWidth: "50rem" }}
            >
              {columns.map((column, index) => (
                <Column
                  key={index}
                  body={column?.body}
                  field={column.field}
                  className="text-sm bg-white text-blue20"
                  header={
                    <div className="flex items-center text-xs font-normal uppercase gap-x-2 text-grey20">
                      <img src={column.icon} alt={column.field} />{" "}
                      {column.header}
                    </div>
                  }
                ></Column>
              ))}
            </DataTable>
          </section>
        </section>
      )}
    </>
  );
}
