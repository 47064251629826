import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//services
import { _getCategories } from "services/category-services";

const initialState = {
  categoryList: {
    isLoading: true,
    data: null,
  },

  productDetails: {
    isLoading: true,
    data: null,
  },
};

//GET
export const getCategories = createAsyncThunk(
  "products/getCategories",
  async () => {
    const response = await _getCategories();

    return response.data;
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    //GET
    //category list
    builder
      .addCase(getCategories.pending, (state) => {
        state.categoryList.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categoryList.isLoading = false;
        state.categoryList.data = payload;
      })
      .addCase(getCategories.rejected, (state, { payload }) => {
        state.categoryList.isLoading = false;
      });
  },
});

export const getCategoriestate = (state) => state.category;
export default categorySlice.reducer;
