import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import send from "assets/svg/Send.svg";
import avatar from "assets/svg/avatar.svg";

//slices
import {
  beginConversation,
  getConversationDetails,
  getConversationList,
  getMessagingState,
  sendMessage,
} from "store/Message/messagingSlice";

//components
import Loader from "components/UI/Loader";
import { useToast } from "components/UI/ToastProvider";

//utils
import { errorToast } from "utils/toastUtil";
import { getUserDetails } from "utils/localStorageServices";
import { getSavedChatProduct, removeChatProduct } from "utils/chatUtils";

//libraries
import { ClipLoader } from "react-spinners";
import moment from "moment";

export default function MessageBox() {
  const dispatch = useDispatch();

  //useref
  const inputBox = useRef(null);

  const { conversationList, conversationDetails } =
    useSelector(getMessagingState);

  //usestates
  const [seller, setSeller] = useState(null);
  const [userId, setUserId] = useState(null);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  //useeffect

  useEffect(() => {
    const userId = getUserDetails()?.id;
    setUserId(userId);
  }, []);

  useEffect(() => {
    conversationDetails.data &&
      setMessages([...conversationDetails.data]?.reverse());
  }, [conversationDetails]);

  useEffect(() => {
    const handleListener = (event) => {
      const product = event.detail;
      initializeMsgBox(product);
    };
    window.addEventListener("switchedConversation", handleListener);

    return () => {
      window.removeEventListener("switchedConversation", handleListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const product = getSavedChatProduct();

    initializeMsgBox(product);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationList, dispatch]);

  //functions

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = moment(message.sentAt).format("MMMM Do, YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const initializeMsgBox = (data) => {
    let arg;
    if (!data) {
      arg = conversationList.data?.[0];
    } else {
      arg = data;
    }

    const sellerName = arg?.seller?.name;
    const productId = arg?.productId ?? arg?.id;

    if (sellerName) {
      const doesExist = conversationList.data?.find(
        (conversation) => conversation?.productId === productId
      );

      if (doesExist) {
        setFirstTime(false);
        productId &&
          dispatch(getConversationDetails(productId)).then(
            ({ type, payload }) => {
              if (type?.includes("fulfilled")) {
                setSeller(doesExist?.secondParticipantName);
              }
            }
          );
      } else {
        setFirstTime(true);
        setSeller(sellerName);
        setProductDetails(arg);
      }
    } else {
      setFirstTime(false);
      const getReceiver = conversationList.data?.find(
        (conversation) => conversation?.id === Number(productId)
      );

      const receiverName =
        getReceiver?.secondParticipantId === getUserDetails()?.id
          ? getReceiver?.firstParticipantName
          : getReceiver?.secondParticipantName;
      setSeller(receiverName);
      arg?.id && dispatch(getConversationDetails(arg?.id));
    }
  };

  const submitMessage = () => {
    if (!message) {
      errorToast("Type in a message");
      return;
    }

    if (firstTime) {
      const data = {
        message,
        productId: productDetails?.id,
      };
      setIsLoading(true);
      dispatch(beginConversation(data)).then(({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          dispatch(getConversationDetails(payload?.id));
          dispatch(getConversationList());
          if (inputBox.current?.value) {
            inputBox.current.value = "";
          }
          setMessage(null);
        }
      });
    } else {
      const conversationId = conversationDetails.data?.[0]?.conversation_id;
      const data = {
        conversationId,
        message,
        attachment: null,
      };

      dispatch(sendMessage(data)).then(({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          if (inputBox.current?.value) {
            inputBox.current.value = "";
          }
          dispatch(getConversationDetails(conversationId));
          setMessage(null);
        }
      });
    }
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <section className="">
      {/* large screen */}
      <section className="border rounded-lg  h-[82vh]    ">
        {((firstTime && !seller) ||
          (!firstTime && conversationDetails.isLoading)) && <Loader />}

        {((firstTime && seller) ||
          (!firstTime && !conversationDetails.isLoading)) && (
          <section className="">
            {/* navbar */}
            <section className=" w-full h-[4rem] pt-4 border-b">
              <section className="flex items-center justify-between w-11/12 mx-auto">
                <section className="flex items-center gap-x-2">
                  <div className="relative">
                    <img src={avatar} alt="" />
                    <div className="absolute bottom-0 right-0 w-2 h-2 bg-green-400 rounded-full"></div>
                  </div>

                  <div className="text-sm">
                    <h4 className="font-semibold text-blue20">{seller}</h4>
                    <p className="text-xs text-grey70">Active</p>
                  </div>
                </section>

                <section className="flex gap-x-2">
                  <i
                    className="cursor-pointer pi pi-search"
                    style={{ color: "#2F2F2F80" }}
                  ></i>

                  <i
                    className="cursor-pointer pi pi-ellipsis-v"
                    style={{ color: "#2F2F2F80" }}
                  ></i>
                </section>
              </section>
            </section>
            {/*  */}

            {/* messages  */}
            <section className="top-[4rem] h-[64vh] overflow-y-scroll">
              <section className="w-[90%] mx-auto relative py-4 flex flex-col gap-y-3">
                {Object.keys(groupedMessages).map((date, index) => (
                  <div key={index}>
                    <div className="mb-2 text-xs text-center bg-[#2F2F2F1A] w-fit px-4 py-2 font-medium mx-auto text-black rounded-full ">
                      {date}
                    </div>
                    {groupedMessages[date].map((message, index) => (
                      <div key={index} className="flex flex-col gap-y-3">
                        {message.participantId === userId ? (
                          <div className="flex justify-end mb-2">
                            <div className="w-auto px-6 py-3 text-sm text-right text-white rounded-t-2xl rounded-bl-2xl bg-blue200">
                              {message.messageText}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-start mb-2">
                            <div className="px-6 py-3 text-sm text-left rounded-t-2xl rounded-br-2xl bg-grey40">
                              {message.messageText}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </section>
            </section>

            {/*  */}

            {/* type message */}

            <section className="w-[90%] mx-auto mt-[-5px] flex flex-col justify-center ">
              <section className="flex items-center justify-between ">
                <input
                  ref={inputBox}
                  type="text"
                  placeholder="Write a message"
                  onKeyDown={(e) => e.key === "Enter" && submitMessage()}
                  onChange={(e) => setMessage(e.target.value)}
                  className="text-sm rounded-lg bg-grey50 outline-none  [ placeholder:text-gray90 placeholder:text-sm ] w-[90%] py-3 pl-4"
                />
                <button
                  disabled={!message}
                  className={`${
                    message
                      ? "opacity-100 cursor-pointer"
                      : "opacity-20 cursor-not-allowed "
                  }`}
                  onClick={submitMessage}
                >
                  {isLoading ? (
                    <ClipLoader size={20} color="blue" />
                  ) : (
                    <img src={send} alt="" className="" />
                  )}
                </button>
              </section>
            </section>

            {/*  */}
          </section>
        )}
      </section>

      {/*  */}
    </section>
  );
}
