import React from "react";

//assets
import search from "assets/svg/greySearch.svg";
import inprogressImg from "assets/svg/inprogress.svg";

//components
import ShoppingTableLG from "components/pages/UserDashboard/Tables/Shopping/ShoppingLG";
import Orderlist from "components/pages/UserDashboard/Tables/MobileTable/Orderlist";

export default function Shopping() {
  const bgGradient = {
    background:
      "linear-gradient(43.09deg, #41A512 -2.16%, #41A512 42.24%, #53C31E 136.59%)",
  };
  return (
    <section className="">
      {/* nav */}

      {/* large screen */}
      <section className=" [ lg:flex hidden items-center justify-between ] ">
        <h1 className="text-lg font-semibold text-blue200">Shopping</h1>

        <section className="[ flex items-center justify-between gap-x-10 ] ">
          <section className="[ flex items-center justify-between gap-x-4 ] ">
            <div className=" [ flex items-center gap-x-2 ] border rounded-full text-blue200  px-4 py-2">
              <p className="text-sm font-semibold ">In progress</p>
              <span className="px-3 py-1 text-xs rounded-full bg-grey50">
                20
              </span>
            </div>

            <div className=" [ flex items-center gap-x-2 ] border rounded-full bg-blue200 text-white  px-4 py-2">
              <p className="text-sm font-semibold">Completedd</p>
              <span className="px-3 py-1 text-xs rounded-full bg-[#FFFFFF26]">
                20
              </span>
            </div>
          </section>

          <section className="w-[18rem]">
            <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
              <img src={search} alt="search box" />
              <input
                type="text"
                className="w-full outline-none [ placeholder:text-sm ] text-sm"
                placeholder="Search"
              />
            </div>
          </section>
        </section>
      </section>
      {/*  */}

      {/* mobile screen */}
      <section className=" [ lg:hidden block ]">
        <section className="flex justify-between ">
          <div>
            <i className="pi pi-arrow-left" style={{ color: "#143D59" }}></i>
          </div>
          <div className="flex items-center justify-center text-center">
            <h4 className="font-semibold text-blue200">Shopping</h4>
          </div>
          <div></div>
        </section>
        {/* search */}
        <section className="w-full my-2">
          <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
            <img src={search} alt="search box" />
            <input
              type="text"
              className="w-full outline-none [ placeholder:text-xs ] text-xs"
              placeholder="Search "
            />
          </div>
        </section>
        {/*  */}

        {/* tabs */}
        <section className="[ flex items-center justify-between ] text-xs gap-x-3 my-3 [ text-blue200 font-medium ]">
          <div className=" [ px-6 py-2 ] text-center rounded-full bg-grey50">
            All
          </div>
          <div className="[ flex items-center gap-x-1 ] [ px-3 py-2 ] text-center rounded-full bg-grey50 ">
            <img src={inprogressImg} alt="" />
            <p>In progress</p>
          </div>

          <div
            className="flex items-center px-4 py-2 text-center text-white rounded-full gap-x-2"
            style={bgGradient}
          >
            <i
              className="pi pi-check"
              style={{ color: "white", fontSize: "12px" }}
            ></i>
            <p>Completed</p>
          </div>
        </section>
        {/*  */}
      </section>
      {/*  */}

      {/* table */}
      <ShoppingTableLG />

      <Orderlist />

      {/*  */}
    </section>
  );
}
