import React, { useEffect } from "react";

//assets
import msg from "assets/svg/msg.svg";
import saved from "assets/svg/saved.svg";
import avatar from "assets/svg/avatar.svg";
import profile from "assets/svg/profile.svg";
import fullLogo from "assets/svg/fullLogo.svg";
import search from "assets/svg/greySearch.svg";
import arrowDown from "assets/svg/arrowDown.svg";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessagingState,
  getUnreadMsgCount,
} from "store/Message/messagingSlice";
import { getUserDetails } from "utils/localStorageServices";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { unreadMsgCount } = useSelector(getMessagingState);

  //useffects
  useEffect(() => {
    dispatch(getUnreadMsgCount());
  }, [dispatch]);

  //variables
  const { firstName } = getUserDetails();

  //functions
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <nav className="border-b ">
      <section className="w-[85%] mx-auto py-4 lg:h-20 h-14">
        <FlexContainer>
          <section>
            <Link to="/">
              <img src={fullLogo} alt="flexbuy logo" className="w-24 lg:w-28" />
            </Link>
          </section>

          <section className="[ flex items-center ] gap-x-10">
            {/* search */}
            {/* <section className="w-[34rem] [ lg:block hidden ]">
              <div className="[ flex items-center ] border gap-x-3 py-3 px-5 rounded-full">
                <img src={search} alt="search box" />
                <input
                  type="text"
                  className="w-full outline-none [ placeholder:text-sm ] text-sm"
                  placeholder="Search for an item you want"
                />
              </div>
            </section> */}
            {/*  */}

            {/* nav options */}
            <section>
              <div className="[ flex items-center ] lg:hidden gap-x-4 relative">
                <div className="absolute top-0 right-0 items-center justify-center h-5 px-2 text-white bg-red-600">
                  {unreadMsgCount.data?.total}
                </div>
                <img src={profile} alt="profile" className="cursor-pointer" />
                <img src={msg} alt="inbox" className="cursor-pointer" />
              </div>
              <FlexContainer extraClass="gap-x-4 hidden lg:flex">
                {/* <div>
                  <FlexContainer extraClass="gap-x-2 text-sm">
                    <img src={saved} alt="saved items" />
                    <p> Saved items</p>
                  </FlexContainer>
                </div> */}

                <div>
                  <Link to="/user/messages">
                    <FlexContainer extraClass="gap-x-2 relative text-sm">
                      <div className="relative">
                        {unreadMsgCount.data?.total && (
                          <div className="w-fit absolute right-[-10px] top-[-10px] rounded-full bg-secondary text-xs text-white h-4 justify-center items-center px-2">
                            {unreadMsgCount.data?.total}
                          </div>
                        )}
                        <img src={msg} alt="inbox" />
                      </div>
                    </FlexContainer>
                  </Link>
                </div>

                <FlexContainer
                  isresponsive={true}
                  extraClass="px-2 cursor-pointer py-1  gap-x-4 border rounded-full items-center"
                >
                  <FlexContainer extraClass="gap-x-2 text-sm">
                    <img src={avatar} alt="avatar" className="w-6" />
                    <p>{firstName}</p>
                  </FlexContainer>

                  <div className="mt-1">
                    <img src={arrowDown} alt="arrow" />
                  </div>
                </FlexContainer>

                <button className="[ flex items-center ] px-4 py-2 text-sm font-semibold rounded-full gap-x-2 text-blue200 bg-primary">
                  <span className="text-sm">Sell a product</span>
                </button>

                <button onClick={logout} className="flex items-center gap-x-1">
                  <i className="pi pi-sign-out"></i>
                  Logout
                </button>
              </FlexContainer>
            </section>
            {/*  */}
          </section>
        </FlexContainer>
      </section>
    </nav>
  );
}
