import React from "react";
import logo from "assets/logo.svg";

export default function Loader() {
  return (
    <div className="h-[70vh] flex justify-center items-center w-full  pulseWrapper">
      <div className="pulse">
        <img src={logo} alt="Page loading " />
      </div>
    </div>
  );
}
