import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

//assets
import search from "assets/svg/greySearch.svg";
import inprogressImg from "assets/svg/inprogress.svg";

//slices
import { getOrderHistory } from "store/Order/orderSlice";

//components
import SalesTable from "./Tables/SalesTable";
import Orderlist from "components/pages/UserDashboard/Tables/MobileTable/Orderlist";


export default function Sales() {
  const dispatch = useDispatch();

  //usestate
  const [tab, setTab] = useState("pending");
  const bgGradient = {
    background:
      "linear-gradient(43.09deg, #41A512 -2.16%, #41A512 42.24%, #53C31E 136.59%)",
  };

  //useffect
  // useEffect(() => {
  //   dispatch(getOrderHistory(tab));
  // }, [dispatch, tab]);

  return (
    <section className="">
      {/* nav */}

      {/* large screen */}
      <section className=" [ lg:flex items-center justify-between ] ">
        <h1 className="text-lg font-semibold text-blue200">Sales</h1>

        <section className="[ flex items-center justify-between gap-x-10 ] ">
          <section className="[ flex items-center justify-between gap-x-4 ] ">
            <button
              onClick={() => setTab("pending")}
              className={` ${
                tab === "pending"
                  ? " bg-blue200 text-white "
                  : "border text-blue200 "
              }  [ flex items-center gap-x-2 ]   rounded-full px-4 py-2 `}
            >
              <p className="text-sm font-semibold ">Pending</p>
              <span
                className={` ${
                  tab === "pending" ? "bg-[#FFFFFF26]" : "bg-grey50"
                } px-3 py-1 text-xs rounded-full `}
              >
                20
              </span>
            </button>

            <button
              onClick={() => setTab("completed")}
              className={` ${
                tab === "completed"
                  ? " bg-blue200 text-white "
                  : "border text-blue200 "
              }  [ flex items-center gap-x-2 ]   rounded-full px-4 py-2 `}
            >
              <p className="text-sm font-semibold">Completed</p>
              <span
                className={` ${
                  tab === "completed" ? "bg-[#FFFFFF26]" : "bg-grey50"
                } px-3 py-1 text-xs rounded-full `}
              >
                20
              </span>
            </button>
          </section>

          <section className="w-[18rem]">
            <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
              <img src={search} alt="search box" />
              <input
                type="text"
                className="w-full outline-none [ placeholder:text-sm ] text-sm"
                placeholder="Search"
              />
            </div>
          </section>
        </section>
      </section>
      {/*  */}

      {/* mobile screen */}
      <section className=" [ lg:hidden block hidden ]">
        <section className="flex justify-between ">
          <div>
            <i className="pi pi-arrow-left" style={{ color: "#143D59" }}></i>
          </div>
          <div className="flex items-center justify-center text-center ml-[-30px]">
            <h4 className="font-semibold text-blue200">Sales</h4>
          </div>
          <div></div>
        </section>
        {/* search */}
        <section className="w-full my-2">
          <div className="[ flex items-center ] border gap-x-3 py-2 px-5 rounded-full">
            <img src={search} alt="search box" />
            <input
              type="text"
              className="w-full outline-none [ placeholder:text-xs ] text-xs"
              placeholder="Search "
            />
          </div>
        </section>
        {/*  */}

        {/* tabs */}
        <section className="[ flex items-center justify-between ] text-xs gap-x-3 my-3 [ text-blue200 font-medium ]">
          <div className=" [ px-6 py-2 ] text-center rounded-full bg-grey50">
            All
          </div>
          <div className="[ flex items-center gap-x-1 ] [ px-3 py-2 ] text-center rounded-full bg-grey50 ">
            <img src={inprogressImg} alt="" />
            <p>In progress</p>
          </div>

          <div
            className="flex items-center px-4 py-2 text-center text-white rounded-full gap-x-2"
            style={bgGradient}
          >
            <i
              className="pi pi-check"
              style={{ color: "white", fontSize: "12px" }}
            ></i>
            <p>Completed</p>
          </div>
        </section>
        {/*  */}
      </section>
      {/*  */}

      {/* table */}
      <SalesTable />

      <Orderlist />

      {/*  */}
    </section>
  );
}
