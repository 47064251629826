import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//utils
import { columns } from "./data";

//libraries
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

//slices
import {
  getMerchantProducts,
  getProductState,
} from "store/Product/productSlice";

//components
import Loader from "components/UI/Loader";
import Pagination from "components/UI/Pagination";

export default function ShoppingTableLG() {
  const dispatch = useDispatch();

  //useselector
  const { merchantProductList } = useSelector(getProductState);
  const products = merchantProductList.data?.products;
  const { data } = merchantProductList ?? {};
  const { pagination } = data ?? {};

  //usestate
  const [pagObj, setPagObj] = useState({
    first: 0,
    rows: 1,
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(
      getMerchantProducts({ page: pagObj.page, page_size: pagObj.pageSize })
    );
  }, [dispatch, pagObj]);

  return (
    <>
      <Pagination
        pagObj={pagObj}
        setPagObj={setPagObj}
        serverPag={pagination}
      />
      {merchantProductList?.isLoading && <Loader />}

      {!merchantProductList?.isLoading && (
        <section className=" space-y-6 bg-white rounded-lg">
          <section className="[ lg:block hidden ]   ">
            <DataTable
              value={products}
              scrollable
              scrollHeight="400px"
              tableStyle={{ minWidth: "50rem" }}
            >
              {columns.map((column, index) => (
                <Column
                  key={index}
                  body={column?.body}
                  field={column.field}
                  className="text-sm bg-white text-blue20"
                  header={
                    <div className="flex items-center text-xs font-normal uppercase gap-x-2 text-grey20">
                      <img src={column.icon} alt={column.field} />{" "}
                      {column.header}
                    </div>
                  }
                ></Column>
              ))}
            </DataTable>
          </section>
        </section>
      )}
    </>
  );
}
