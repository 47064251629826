import React from 'react'

//assets
import searchCircle from 'assets/svg/searchCircle.svg'
import left from 'assets/svg/right2.svg'

export default function Searches() {
  return (
  <section className='flex flex-col gap-y-4'>

  <section className='flex justify-between pb-3 border-b'>
    <div className='flex items-center gap-x-1'>
        <img src={searchCircle} alt="" />
        <p className='text-sm'>Bucket hat</p>
    </div>

    <div>
        <img src={left} alt="" />
    </div>
  </section>

  <section className='flex justify-between pb-3 border-b'>
    <div className='flex items-center gap-x-1'>
        <img src={searchCircle} alt="" />
        <p className='text-sm'>Bucket hat</p>
    </div>

    <div>
        <img src={left} alt="" />
    </div>
  </section>

  <section className='flex justify-between pb-3 border-b'>
    <div className='flex items-center gap-x-1'>
        <img src={searchCircle} alt="" />
        <p className='text-sm'>Bucket hat</p>
    </div>

    <div>
        <img src={left} alt="" />
    </div>
  </section>

  <section className='flex justify-between pb-3 border-b'>
    <div className='flex items-center gap-x-1'>
        <img src={searchCircle} alt="" />
        <p className='text-sm'>Bucket hat</p>
    </div>

    <div>
        <img src={left} alt="" />
    </div>
  </section>

  </section>
  )
}
