import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//slices
import {
  getConversationList,
  getUnreadMsgCount,
} from "store/Message/messagingSlice";

//components
import MessageLargeScreen from "components/pages/UserDashboard/Message/LargeScreen";
import MessageMobileScreen from "components/pages/UserDashboard/Message/MobileScreen";

export default function Message() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConversationList());
    dispatch(getUnreadMsgCount());
  }, [dispatch]);

  return (
    <section>
      <section className="[ lg:block hidden ]">
        <MessageLargeScreen />
      </section>

      <section className=" [ lg:hidden block ] ">
        <MessageMobileScreen />
      </section>
    </section>
  );
}
