export const getToken = () => {
  const user = localStorage.getItem("token");
  if (!user) {
    return null;
  }

  return JSON.parse(user);
};

export const getUserDetails = () => {
  const user = localStorage.getItem("user-details");
  if (!user) {
    return null;
  }

  return JSON.parse(user);
};

export const localStorageGetter = (key) => {
  if (!key) return;
  const data = localStorage.getItem(key);
  return data && JSON.parse(data);
};

export const localStorageSetter = (key, value) => {
  if (!key) return;
  localStorage.setItem(key, JSON.stringify(value));
};
