import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets

//components
import Orderlist from "components/pages/UserDashboard/Tables/MobileTable/Orderlist";
import LargeScreenNav from "components/pages/UserDashboard/Products/LargeScreenNav";
import MobileScreenNav from "components/pages/UserDashboard/Products/MobileScreenNav";
import ShoppingTableLG from "components/pages/UserDashboard/Tables/Shopping/ShoppingLG";

//slices
import { getMerchantProducts } from "store/Product/productSlice";

export default function Products() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getMerchantProducts());
  // }, [dispatch]);

  return (
    <section className="">
      {/* nav */}

      {/* large screen */}
      <LargeScreenNav />
      {/*  */}

      {/* mobile screen */}
      <MobileScreenNav />
      {/*  */}

      {/* table */}
      <ShoppingTableLG />

      <Orderlist />

      {/*  */}
    </section>
  );
}
