import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//assets
import chat from "assets/svg/chat.svg";
import dislike from "assets/svg/dislike.svg";
import location from "assets/svg/blueLocationPin.svg";

//libraries
import { Drawer, Modal } from "antd";

//components
import LoginModal from "views/Authentication/Modal/LoginModal";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import BuyNowDrawer from "views/Website/ProductDetails/Drawer/BuyNowDrawer";

//utils
import { formatAmount } from "utils/formatter";
import { getUserDetails } from "utils/localStorageServices";

//slices
import { resetChatbox } from "store/Message/messagingSlice";
import { saveProductForChat } from "utils/chatUtils";

export default function BuyOptions({ product }) {
  const { price, name } = product ?? {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = getUserDetails();
  const { address } = userDetails ?? {};

  //usestate
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isBuyNowDrawerOpen, setIsBuyNowDrawerOpen] = useState(false);

  //function
  const closeLoginModal = () => {
    setOpenLoginModal(false);
  };

  const closeBuyNowDrawer = () => {
    setIsBuyNowDrawerOpen(false);
  };

  const startConversation = () => {
    if (userDetails) {
      dispatch(resetChatbox());
      saveProductForChat(product);
      navigate("/user/messages");
    } else {
      openLoginModal(true);
    }
  };

  return (
    <section className="my-4">
      <div className="dmSans">
        <p className=" [ lg:text-[1.5rem] text-sm ]">{name} </p>
        <h2 className=" [ lg:text-[2rem] text-2xl ] font-bold my-4">
          {formatAmount(price)}
        </h2>
        {/* <p className="text-grey70 [ lg:text-lg text-sm ]">
          Neatly used | Suade material
        </p> */}
      </div>

      <FlexContainer extraClass="my-4 text-sm">
        <div className="flex items-center gap-x-4">
          <button
            onClick={() =>
              userDetails
                ? setIsBuyNowDrawerOpen(true)
                : setOpenLoginModal(true)
            }
            className="font-semibold rounded-full bg-primary text-blue200 [ lg:px-5 py-2  px-4 ]  "
          >
            Buy now
          </button>

          <button
            onClick={startConversation}
            className="flex items-center  font-semibold border rounded-full gap-x-2 text-blue200 [ lg:px-5 py-2  px-4 ]  "
          >
            <img src={chat} alt="" />
            <span>Chat with seller </span>
          </button>

          {/* <div className="p-3 py-3 border rounded-full cursor-pointer ">
            <img src={dislike} alt="" />
          </div> */}
        </div>

        <div className="[ lg:flex hidden ] items-center gap-x-2">
          {" "}
          <img src={location} alt="" /> <p> Lagos</p>{" "}
        </div>
      </FlexContainer>

      {/* Modal  */}
      <>
        <Modal
          width={900}
          open={openLoginModal}
          onCancel={closeLoginModal}
          footer={null}
        >
          <LoginModal closeModal={closeLoginModal} />
        </Modal>
      </>
      {/*  */}

      {/* Drawer  */}
      <>
        <Drawer
          width="30%"
          destroyOnClose={true}
          open={isBuyNowDrawerOpen}
          onClose={closeBuyNowDrawer}
          styles={{ header: { display: "none" }, body: { paddingTop: 0 } }}
         
        >
          <BuyNowDrawer
            address={address}
            product={product}
            chatSeller={startConversation}
            closeDrawer={closeBuyNowDrawer}
          />
        </Drawer>
      </>
      {/*  */}
    </section>
  );
}
