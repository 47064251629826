import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//services
import { _getWalletBalance } from "services/wallet-service";

const initialState = {
  walletBalance: {
    isLoading: true,
    data: null,
  },
};

//GET
export const getWalletBalance = createAsyncThunk(
  "wallet/getWalletBalance",
  async () => {
    const response = await _getWalletBalance();

    return response.data;
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  extraReducers: (builder) => {
    //GET
    //province list
    builder
      .addCase(getWalletBalance.pending, (state) => {
        state.walletBalance.isLoading = true;
      })
      .addCase(getWalletBalance.fulfilled, (state, { payload }) => {
        state.walletBalance.isLoading = false;
        state.walletBalance.data = payload;
      })
      .addCase(getWalletBalance.rejected, (state, { payload }) => {
        state.walletBalance.isLoading = false;
      });
  },
});

export const getWalletState = (state) => state.wallet;
export default walletSlice.reducer;
