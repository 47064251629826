import React from "react";

//components
import FlexContainer from "../../../UI/StyleComponents/FlexContainer";

//assets
import plus from "assets/svg/whitePlus.svg";
import twitter from "assets/svg/Twitter.svg";
import yellowBg from "assets/svg/yellowBg.svg";
import logoName from "assets/svg/logoName.svg";
import linkedIn from "assets/svg/linkedIn.svg";
import instagram from "assets/svg/Instagram.svg";
import whiteCard from "assets/svg/whiteCard.svg";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const { pathname } = useLocation();

  //variables
  const showBanner = pathname === "/" || pathname === "/search-result";

  const yellowStyle = {
    backgroundImage: `url(${yellowBg})`,
  };

  return (
    <section>
      {showBanner && (
        <section
          style={yellowStyle}
          className="lg:w-[80%] mx-auto lg:rounded-2xl h-[20rem] w-full "
        >
          <div className="lg:w-[90%] flex lg:flex-row flex-col justify-between items-center h-full items-center py-4">
            {" "}
            <div className="lg:w-[70%] flex justify-center flex-col gap-y-4  h-full lg:px-20  w-[80%]">
              <h1 className="lg:text-[2.75rem] lg:text-left text-center text-lg font-bold text-blue200 lg:leading-10 leading-5">
                The convenient way to buy used items
              </h1>
              <div className="flex justify-center w-full lg:justify-start">
                <button className="flex items-center w-40 px-4 py-3 text-sm text-white rounded-full bg-blue200 gap-x-1">
                  <img src={plus} alt="" className="w-4" />{" "}
                  <span>Create project</span>
                </button>
              </div>
            </div>
            <div>
              <img src={whiteCard} alt="" />
            </div>
          </div>

          <p className="px-4 my-20 text-xs lg:my-8 lg:text-sm">
            “If you don’t use it, list it.” That’s the premise under which
            Wallapop has grown into a community where millions of people buy and
            sell second-hand items every day. And we’re only just getting
            started. Buying second-hand isn’t just the best way to get what you
            need for a better price. Every time you buy something on Wallapop,
            you promote more sustainable consumption because you extend the
            useful life of items and turn away from overproduction. Selling
            second-hand items is telling the world we can live with less. More
            space at home, more money in your wallet, more new experiences, more
            unforgettable memories, more of what you want, and less storing away
            unused stuff in your closet. Society finally understands that
            second-hand is a new way to shop that is full of benefits. That’s
            why more and more people use Wallapop, the leading platform for
            second-hand sales and classified ads.
          </p>
        </section>
      )}

      <footer className="my-40 lg:my-8 w-[80%] mx-auto py-10">
        <section className="flex flex-col pt-4 pb-10 mb-4 border-b mt-36 lg:mt-24 lg:justify-between lg:flex-row">
          <section className="lg:w-[30%] lg:mb-0 mb-8">
            <img src={logoName} alt="" className="w-36 lg:w-40" />
          </section>

          <section className="grid lg:grid-cols-4 grid-cols-2 lg:w-[60%] gap-y-8  w-full">
            <div>
              <h4 className="text-sm font-semibold lg:text-sm text-blue800">
                Product
              </h4>

              <div className="flex flex-col mt-2 text-sm gap-y-2 lg:text-sm">
                <p className="text-blue10">Features</p>
                <p className="text-blue10">Pricing</p>
                <p className="text-blue10">Case studies</p>
                <p className="text-blue10">Reviews</p>
                <p className="text-blue10">Updates</p>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold lg:text-sm text-blue800">
                Company
              </h4>

              <div className="flex flex-col mt-2 text-sm gap-y-2 lg:text-sm">
                <p className="text-blue10">About</p>
                <p className="text-blue10">Contact us</p>
                <p className="text-blue10">Careers</p>
                <p className="text-blue10">Culture</p>
                <p className="text-blue10">Blog</p>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold lg:text-sm text-blue800">
                Support
              </h4>

              <div className="flex flex-col mt-2 text-sm gap-y-2 lg:text-sm">
                <p className="text-blue10">Getting started</p>
                <p className="text-blue10">Help center</p>
                <p className="text-blue10">Server status</p>
                <p className="text-blue10">Report a bug</p>
                <p className="text-blue10">Chat support</p>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold lg:text-sm text-blue800">
                Downloads
              </h4>

              <div className="flex flex-col mt-2 text-sm gap-y-2 lg:text-sm">
                <p className="text-blue10">IOS</p>
                <p className="text-blue10">Andriod</p>
                <p className="text-blue10">Mac</p>
                <p className="text-blue10">Windows</p>
                <p className="text-blue10">Chrome</p>
              </div>
            </div>
          </section>
        </section>

        <section className="">
          <FlexContainer isresponsive={true}>
            <p className="text-xs text-blue10 lg:text-sm">
              Copyright © 2023 Flexbuy | All Rights Reserved{" "}
            </p>

            <div className="flex items-center gap-x-3">
              <img src={twitter} alt="twitter" className="w-5" />
              <img src={instagram} alt="instagram" className="w-5" />
              <img src={linkedIn} alt="linkedIn" className="w-5 mt-[-3px]" />
            </div>
          </FlexContainer>
        </section>
      </footer>
    </section>
  );
}
