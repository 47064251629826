import { Toast } from "primereact/toast";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { setToast } from "utils/toastUtil";

const ToastContext = createContext();
export default function ToastProvider({ children }) {
  const toast = useRef();

  useEffect(() => {
    setToast(toast.current);
  }, []);

  return (
    <ToastContext.Provider value={toast}>
      <Toast ref={toast} className="text-sm" position="top-center" />
      {children}
    </ToastContext.Provider>
  );
}

export const useToast = () => {
  return useContext(ToastContext);
};
