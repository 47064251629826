import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//assets
import envelop from "assets/svg/envelop.svg";

//libraries
import axios from "axios";
import { baseURL } from "utils/baseUrl";
import { ClipLoader } from "react-spinners";
import { errorToast } from "utils/toastUtil";

export default function ResetPassword() {
  //useref
  const emailRef = useRef();

  //usestate
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let email = emailRef.current.value;

    axios
      .post(`${baseURL}/users/forgot_password`, { email })
      .then((res) => {
        setIsSent(true);
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section>
      <section>
        {!isSent && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold text-center text-blue200">
              Reset your password
            </h2>
          </div>
        )}

        <section className=" flex flex-col justify-center w-[50%] mx-auto  ">
          {!isSent && (
            <form className="" onSubmit={forgotPassword}>
              <div className="">
                <div className="w-full text-center">
                  <label className="my-4 text-sm font-semibold text-center text-blue200 ">
                    Enter your email address
                  </label>
                </div>

                <input
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 border-2 rounded-lg outline-none"
                />
              </div>

              <button className="w-full py-3 mt-4 text-sm font-semibold rounded-full bg-yellow800 text-grey100">
                {isLoading ? (
                  <ClipLoader color="white" size={12} />
                ) : (
                  " Send reset link"
                )}
              </button>
            </form>
          )}

          {isSent && (
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-center">
                <img src={envelop} alt="reset code sent" className="w-16" />
              </div>
              <h2 className="text-lg font-semibold text-center text-blue200">
                Reset link sent
              </h2>
              <p className="text-sm text-center text-gray-800">
                A link has been sent to {emailRef?.current?.value} you for
                easily reset your password. Check your spam box if you can’t
                find it in your inbox
              </p>

              <button
                onClick={() => setIsSent(false)}
                className="w-full py-3 mt-4 text-sm font-semibold rounded-full bg-yellow800 text-grey100"
              >
                Resend link
              </button>
            </div>
          )}

          <section className="mt-4">
            <p className="my-4 font-semibold text-center ">
              Don’t have an account?
              <Link to="/register">
                <span className="underline text-blue200 underline-offset-2">
                  {" "}
                  Register
                </span>
              </Link>
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}
