import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//primereact
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";

//router
import { router } from "routes";
import { RouterProvider } from "react-router-dom";

//rtk
import store from "store/store";
import { Provider } from "react-redux";
import ToastProvider from "components/UI/ToastProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastContainer
      limit={3}
      autoClose={2000}
      position="top-center"
      hideProgressBar
    />
    <ToastProvider>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </ToastProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
