import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "utils/localStorageServices";

export default function ProtectedRoute({ children }) {
  if (getToken()) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
}
