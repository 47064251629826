import React from "react";
import { useLocation } from "react-router-dom";

//assets
import right from "assets/svg/rightActiveCarousel.svg";
import left from "assets/svg/leftDisabledCarousel.svg";

//components
import ProductCard from "./ProductCard";
import FlexContainer from "../StyleComponents/FlexContainer";

export default function ProductList({ productList }) {
  const { pathname } = useLocation();
  const products = productList.products;
 
  return (
    <section className="my-16">
      {pathname === "/" && (
        <section>
          <FlexContainer>
            <h2 className="text-lg font-semibold lg:text-xl text-blue200">
              The best, at the best price
            </h2>

            <div className="hidden lg:flex gap-x-4 ">
              <img
                src={left}
                alt="previous"
                className="w-10 cursor-not-allowed"
              />

              <img src={right} alt="next" className="w-10 cursor-pointer" />
            </div>
          </FlexContainer>
        </section>
      )}

      {/* products */}
      <section className="my-6 ">
        {products?.length > 0 ? (
          <section className="grid grid-cols-2 my-6 lg:grid-cols-4 gap-x-4 lg:gap-x-10 gap-y-4">
            {products?.map((product, index) => {
              return <ProductCard product={product} key={index} />;
            })}
          </section>
        ) : (
          <div className="flex items-center justify-center w-full ">
            <p className="font-bold text-center text-blue200">
              No result found{" "}
            </p>
          </div>
        )}
      </section>

      {/*  */}
    </section>
  );
}
