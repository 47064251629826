import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//assets
import box from "assets/svg/box.svg";
import filter from "assets/svg/filter.svg";
import priceTag from "assets/svg/priceTag.svg";
import arrowDown from "assets/svg/arrowDown.svg";
import catefilter from "assets/svg/catefilter.svg";
import locationPin from "assets/svg/locationPin.svg";
import ProductList from "components/UI/Product/ProductList";

//slices
import { getProductState, getProducts } from "store/Product/productSlice";

//components
import Loader from "components/UI/Loader";
import Footer from "components/pages/Website/Layout/Footer";
import Navbar from "components/pages/Website/Layout/Navbar";
import { Modal } from "antd";
import CategoryModal from "components/pages/Website/Modals/CategoryModal";
import PriceRangeModal from "components/pages/Website/Modals/PriceRangeModal";
import { formatAmount } from "utils/formatter";

export default function SearchPage() {
  const dispatch = useDispatch();

  //useselectors
  const { productList } = useSelector(getProductState);
  const { isLoading = true, data = [] } = productList ?? {};

  //usestate
  const [searchParams] = useSearchParams();
  const PN = searchParams.get("PN");

  const [activeCate, setActiveCate] = useState("");
  const [priceRange, setPriceRange] = useState(null);
  const [isPRModalOpen, setIsPRModalOpen] = useState(false);
  const [isCateModalOpen, setIsCateModalOpen] = useState(false);

  // useeffect
  useEffect(() => {
    if (PN) {
      const params = {
        product_name: PN,
        category_id: activeCate?.id,
      };
      dispatch(getProducts(params));
    }
  }, [dispatch, searchParams, PN, activeCate]);

  const applyFilter = () => {
    closePRModal();
    const params = {
      product_name: PN,
      category_id: activeCate?.id,
      min_amount: priceRange?.[0],
      max_amount: priceRange?.[1],
    };
    dispatch(getProducts(params));
  };

  //functions
  const openCateModal = () => {
    setIsCateModalOpen(true);
  };

  const closeCateModal = () => {
    setIsCateModalOpen(false);
  };

  const openPRModal = () => {
    setIsPRModalOpen(true);
  };

  const closePRModal = () => {
    setIsPRModalOpen(false);
  };

  return (
    <section>
      <Navbar />

      {/* main */}
      <main className="w-[80%] mx-auto my-10 ">
        <section className="my-10">
          {/* filters */}
          <section className="flex items-center justify-center overflow-x-scroll gap-x-4 hide-scrollbar">
            <div className="flex items-center font-medium cursor-pointer gap-x-1 text-blue200">
              <img src={filter} alt="filter" />
              <p>Filter</p>
            </div>

            <div
              onClick={openCateModal}
              className={`flex items-center justify-between min-w-40 gap-x-3  px-4 py-2 text-sm font-medium border rounded-full cursor-pointer ${
                activeCate?.name
                  ? "bg-primary text-blue200 font-semibold"
                  : "bg-transparent"
              }`}
            >
              <div className="flex items-center gap-x-2">
                <img src={catefilter} alt="location" />
                <p>{activeCate?.name ?? "Category"}</p>
              </div>

              <img src={arrowDown} alt="" className="w-3 " />
            </div>

            <div
              onClick={openPRModal}
              className={`flex items-center justify-between min-w-40 gap-x-3  px-4 py-2 text-sm font-medium border rounded-full cursor-pointer ${
                priceRange
                  ? "bg-primary text-blue200 font-semibold"
                  : "bg-transparent"
              }`}
            >
              <div className="flex items-center gap-x-2">
                <img src={priceTag} alt="price tag" />
                <p>
                  {priceRange
                    ? formatAmount(priceRange[0]) +
                      "- " +
                      formatAmount(priceRange[1])
                    : "Price"}
                </p>
              </div>

              <img src={arrowDown} alt="" className="w-3 mt-1" />
            </div>

            <div className="flex items-center justify-between w-40 px-4 py-2 text-sm font-medium border rounded-full cursor-pointer">
              <div className="flex items-center gap-x-2">
                <img src={locationPin} alt="location" />
                <p>Location</p>
              </div>

              <img src={arrowDown} alt="" className="w-3 mt-1" />
            </div>

            {/* <div className="flex items-center justify-between w-48 px-4 py-2 text-sm font-medium border rounded-full cursor-pointer">
              <div className="flex items-center gap-x-2">
                <img src={box} alt="Item condition" />
                <p>Item condition</p>
              </div>

              <img src={arrowDown} alt="" className="w-3 mt-1" />
            </div> */}
          </section>
          {/*  */}
          <section className="my-4 text-2xl">
            Search <span className="ml-4 font-semibold">"{PN}"</span>{" "}
          </section>
          {/* product list */}
          <section>
            {isLoading && <Loader />}

            {!isLoading && <ProductList productList={data} />}
          </section>

          {/*  */}
        </section>
      </main>
      {/*  */}
      <Footer />

      {/* Modals */}
      <Modal
        width={900}
        open={isCateModalOpen}
        onCancel={closeCateModal}
        footer={null}
      >
        <CategoryModal
          PN={PN}
          closeModal={closeCateModal}
          setActiveCate={setActiveCate}
        />
      </Modal>

      <Modal open={isPRModalOpen} onCancel={closePRModal} footer={null}>
        <PriceRangeModal
          applyFilter={applyFilter}
          closeModal={closePRModal}
          setPriceRange={setPriceRange}
        />
      </Modal>
      {/*  */}
    </section>
  );
}
