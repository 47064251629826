import React from 'react'

//assets
import shirt from "assets/image/shirt.png"

export default function Products() {
  return (
    <section>
        <section className='grid grid-cols-6 gap-x-8 gap-y-4'>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>
            <img src={shirt} alt="" className='rounded-lg '/>

        </section>

    </section>
  )
}
