import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//components
import Loader from "components/UI/Loader";
import BuyOptions from "components/pages/Website/ProductDetails/BuyOptions";
import Description from "components/pages/Website/ProductDetails/Description";
import ImageGallery from "components/pages/Website/ProductDetails/ImageGallery";

//slices
import { getProductDetails, getProductState } from "store/Product/productSlice";

export default function ProductDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  //useselector
  const { productDetails } = useSelector(getProductState);

  const { isLoading = true, data = [] } = productDetails ?? {};

  //useffect
  useEffect(() => {
    dispatch(getProductDetails(id));
  }, [dispatch, id]);

  return (
    <section className="flex ">
      {/* breadcrumb */}
      <section className="hidden lg:flex ">
        <p className="text-sm text-blue10">
          Home {">"} Furnitures {">"}
          <span className="font-semibold text-blue200">
            {data?.name ?? "..."}
          </span>
        </p>
      </section>
      {/*  */}

      {isLoading && <Loader />}

      {!isLoading && data && (
        <>
          {/* main */}
          <section className="mx-auto my-8 ">
            {/* image gallery */}
            <ImageGallery images={data.photos} />
            {/*  */}

            {/* buy & options */}
            <BuyOptions product={data} />
            {/*  */}

            <Description product={data} />
          </section>
          {/*  */}
        </>
      )}
    </section>
  );
}
