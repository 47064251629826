import React from "react";

//utils
import { formatAmount } from "utils/formatter";

//assets
import lock from "assets/svg/lock.svg";
import mark from "assets/svg/mark.svg";
import { Link } from "react-router-dom";

export default function ProductCard({ product }) {
  const { name, photos, status, price } = product;

  const lowerStatus = status?.toLowerCase();
  return (
    <Link to={`/product/${product.id}`}>
      <div className={` cursor-pointer relative`}>
        <>
          {lowerStatus !== "available" && (
            <div
              className={` z-50 text-xs px-3 py-1 absolute flex items-center font-medium rounded-md gap-x-1 border-blue200 top-4 right-4 ${
                lowerStatus === "reserved" ? "bg-white" : "border"
              } `}
            >
              <img src={lowerStatus === "reserved" ? lock : mark} alt=""  />
              <p>{status}</p>
            </div>
          )}
        </>

        <div className={`${lowerStatus !== "available" && "opacity-50"} `}>
          <img src={photos?.[0]?.url} alt={name} className="rounded-lg" />

          <div className="flex flex-col mt-4 gap-y-1">
            <p className="text-xs lg:text-sm">{name}</p>
            <p className="text-sm font-semibold lg:text-lg">
              {formatAmount(price)}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
