import React, { useEffect, useState } from "react";

//libraries
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import Axios from "lib/axiosInterceptor";
import { ClipLoader } from "react-spinners";
import { getUserDetails } from "utils/localStorageServices";

export default function EmailVerification() {
  // usestate
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const token = searchParams.get("token");
  useEffect(() => {
    let { status } = getUserDetails();
    const confirmEmail = () => {
      Axios.get(`users/verify_email?token=${token}`)
        .then((res) => {
          toast.success("Email verified");
          localStorage.clear();
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (status !== "ACTIVE") {
      confirmEmail();
    }
  }, [token]);

  return (
    <section className="flex flex-col items-center justify-center h-screen ">
      {isLoading && (
        <section className="flex flex-col items-center justify-center h-screen gap-y-4">
          <div>Hold on while confirmation is done</div>
          {isLoading && <ClipLoader />}
        </section>
      )}

      {!isLoading && (
        <section className="flex flex-col items-center justify-center h-screen gap-y-4">
          Email verification successfull
          <Link to="/login">
            {" "}
            <p className="font-medium text-primary"> Let's go home</p>
          </Link>
        </section>
      )}
    </section>
  );
}
