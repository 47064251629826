import React, { useEffect, useState } from "react";

//asset
import successMark from "assets/svg/yellowMark.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { orderPaymentStatus } from "store/Order/orderSlice";
import Loader from "components/UI/Loader";

export default function PaymentSuccessful({ chatSeller, serverOrderCode }) {
  const dispatch = useDispatch();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(orderPaymentStatus(serverOrderCode)).then(({ type }) => {
      setIsLoading(false);
      if (type.includes("fulfilled")) {
        setIsSuccessful(true);
      } else {
        setIsSuccessful(false);
      }
    });
  }, [dispatch, serverOrderCode]);

  return (
    <section className="">
      {isLoading && <Loader />}

      {!isLoading && (
        <section>
          {isSuccessful ? (
            <div className="flex justify-center lightYellowGradient">
              <img src={successMark} alt="" />
            </div>
          ) : (
            <div className="flex justify-center rounded-xl bg-red-50">
              <div className="my-10 bg-red-700 rounded-full">
                <i className="p-8 text-4xl text-white pi pi-times"></i>
              </div>
            </div>
          )}

          <section className="w-10/12 p-8 mx-auto text-center">
            {isSuccessful && (
              <section>
                <h3 className="text-lg font-semibold">
                  Your payment was successful!
                </h3>
                <p className="mt-4 text-[0.875rem] font-medium text-grey120">
                  The item would be delivered to the selected address. Kindly
                  check your messages for message
                </p>

                <div className="flex flex-col my-4 font-semibold gap-y-3">
                  {/* <button
                    onClick={chatSeller}
                    className="w-full py-4 border rounded-full"   
                    className="w-full py-4 rounded-full outline-none yellowGradient text-blue200"
                  >
                    Chat with seller
                  </button> */}
                  <Link to="/">
                    <button className="w-full py-4 rounded-full outline-none yellowGradient text-blue200">
                      Continue shopping
                    </button>
                  </Link>
                </div>
              </section>
            )}

            {!isSuccessful && (
              <section className="">
                <h3 className="text-lg font-semibold text-red-700">
                  Payment failed!
                </h3>
                <p className="mt-4 text-[0.875rem] font-medium text-grey120">
                  Please try again or contact our support team.
                </p>
              </section>
            )}
          </section>
        </section>
      )}
    </section>
  );
}
