import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "utils/localStorageServices";

export default function AuthRoute({ children }) {
  const { pathname } = useLocation();

  if (pathname?.includes("api") && getToken()) {
    localStorage.clear();
    return children;
  }
  if (!getToken()) {
    return children;
  } else {
    return <Navigate to="/user/shopping" replace />;
  }
}
